import React, {useEffect, useState} from "react";
import { joinProject } from "../../../../../api/project";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";
import {findUserByEmail, addAdminToProject} from "../../../../../api/user";
import {isValidEmail} from "../../../../../utils/helpers";

const EditProjectAdminsPopup = ({ show, onClose, projectId }) => {
    const [email, setEmail] = useState("");
    const [emailFound, setEmailFound] = useState(false);
    const [foundUserId, setFoundUserId] = useState("");

    const addEmail = async () => {
        try {
            await addAdminToProject(foundUserId, projectId);
            onClose();
        } catch (err) {
            console.error(err);
        }
    };

    const findByEmail = async (email) => {
        if (email?.length > 0 && isValidEmail(email)) {
            try {
                const userFound = await findUserByEmail(email);
                if (userFound) {
                    setFoundUserId(userFound.uid);
                }
                setEmailFound(!!userFound);
            } catch (e) {
                setEmailFound(false);
            }
        }
    };

    useEffect(() => {
        setEmailFound(false);
        findByEmail(email).then();
    }, [email]);

    return (
        <Popup
            title="Edit Admins"
            width={600}
            contentComponent={
                <div className="flex flex-col">
                    {<input
                        id="adminEmail"
                        name="adminEmail"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Admin Email"
                    />}
                    { emailFound && <>
                        <br />
                        <button
                            onClick={addEmail}
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                            + Add
                        </button>
                    </>
                    }
                    <br/>
                    {emailFound && <div className="text-green-500">User found</div>}
                    {!emailFound && email.length > 0 && <div className="text-red-500">User not found</div>}
                </div>
            }
            onCancel={onClose}
            show={show}
            showFooter={false}
            onClose={onClose}
            allowSubmit={true}
        />
    );
};

export default EditProjectAdminsPopup;
