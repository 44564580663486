const Chip = ({ text, onRemove, classes = "" }) => {
  return (
    <div
        className={`flex items-center text-white text-xs rounded-md mr-2 pl-2 pr-3 py-1 bg-indigo-600 ${classes}`}>
      {onRemove && (
        <div
          onClick={onRemove}
          className="cursor-pointer hover:opacity-60 bg-[#968ef7] p-1 text-white flex items-center justify-center mr-2 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-3 h-3 stroke-current"
            onClick={onRemove}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
      )}
      {text}
    </div>
  );
};

export default Chip;
