import { useEffect, useState } from "react";
import { getMapByProjectAndStudent } from "../../../../api/map";
import { getUserByFirebaseid } from "../../../../api/user";
import { ROLES } from "../../../../utils/entities";
import { STUDENT_VIEW_MODE } from "../../../../utils/student-view-mode";
import Cyto from "../../../shared/cyto/Cyto";
import Spinner from "../../../shared/spinner/Spinner";
import {useUserAuth} from "../../../../context/userAuthContext";

const StudentProject = ({ project, studentUid, viewMode, allowEdit = true }) => {
  const [student, setStudent] = useState();
  const [studentMap, setStudentMap] = useState();
  const [loadingMap, setLoadingMap] = useState(false);
  const { user } = useUserAuth();
  const [map, setMap] = useState();

  const fetchMap = async () => {
    const studentMap = await getMapByProjectAndStudent(project.id, studentUid);
    setStudentMap(studentMap);
  };

  const fetchStudent = async () => {
    const student = await getUserByFirebaseid(studentUid);
    setStudent(student);
    await fetchMap();
  };

  const loadData = async () => {
    try {
      setLoadingMap(true);
      await fetchStudent();
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMap(false);
    }
  };

  useEffect(() => {
    loadData().then();
  }, []);

  useEffect(() => {
    if (studentMap) {
      if (!viewMode || viewMode === STUDENT_VIEW_MODE.STUDENT) {
        setMap(studentMap);
      } else if (viewMode === STUDENT_VIEW_MODE.PROJECT) {
        setMap(project.mergedMap || project.defaultMap);
      }
    }
  }, [viewMode, studentMap]);

  return (
    <>
      {loadingMap && <Spinner text="Loading map..." />}
      {!loadingMap && project && (
        <div
          className="flex w-full flex"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="flex flex-col flex-1 px-5 py-5 mb-4">
            <div className="flex-1 rounded-lg bg-white h-[500px] border-2 border-dashed border-gray-200">
              {map && (
                <Cyto
                  mapName={
                    student && user?.role === ROLES.TEACHER
                      ? `Viewing: ${student.name}'s map`
                      : viewMode === STUDENT_VIEW_MODE.STUDENT
                      ? "Your map"
                      : `Project's ${
                          project.mergedMap ? "merged" : "default"
                        } map`
                  }
                  project={project}
                  allowEdit={allowEdit}
                  mapId={map.id}
                  nodes={map.entities.concepts}
                  edges={map.entities.relationships}
                  showMetrics={user?.role === ROLES.TEACHER}
                  requireCitations={project.requireCitations}
                  parentMapEntities={{
                    nodes: (project.mergedMap || project.defaultMap).entities
                      .concepts,
                    edges: (project.mergedMap || project.defaultMap).entities
                      .relationships,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentProject;
