import axios from "./axios-interceptor";
import { downloadFile } from "../utils/helpers";

export const createProject = async ({
  uid,
  name,
  editable,
  requireCitations,
  defaultMapStorageUrl,
  course,
  emailSuffix,
}) => {
  const { data } = await axios.post(`project`, {
    uid,
    name,
    editable,
    requireCitations,
    defaultMapStorageUrl,
    course,
    emailSuffix,
  });
  return data;
};

export const updateProject = async ({ id, archived }) => {
  const { data } = await axios.put(`project/${id}`, {
    archived,
  });
  return data;
};

export const editProject = async (id, payload) => {
  const { data } = await axios.put(`project/${id}/edit`, payload);
  return data;
};

export const joinProject = async (projectId) => {
  const { data } = await axios.post(`project/join`, {
    projectId,
  });
  return data;
};

export const getSnapshots = async (projectId, name) => {
  const { data } = await axios.get(`project/${projectId}/snapshots`);
  return data;
};

export const downloadSnapshotGradingSheet = async (
  projectId,
  snapshotId,
  filename,
) => {
  const { data } = axios.get(
    `project/${projectId}/snapshots/${snapshotId}/grading-sheet`,
  );
  return data;
};

export const takeSnapshot = async (projectId, snapshotName) => {
  const { data } = await axios.post(`project/${projectId}/snapshot`, {
    projectId,
    snapshotName,
  });
  return data;
};

export const getProjectGeneratedId = async () => {
  const { data } = await axios.get(`project/generated/id`);
  return data;
};

export const getProjects = async () => {
  const { data } = await axios.get(`project`);
  return data;
};

export const removeProjectAdmin = async (projectId, adminId) => {
  await axios.delete(`project/${projectId}/admin/${adminId}`);
};

export const getProjectStudents = async (id) => {
  const { data } = await axios.get(`project/${id}/students`);
  return data;
};

export const getProjectById = async (id) => {
  const { data } = await axios.get(`project/${id}`);
  return data;
};

export const getProjectLogs = async (id) => {
  const { data } = await axios.get(`project/${id}/logs`);
  return data;
};

export const getUserProjectsWithMaps = async (id) => {
  const { data } = await axios.get(`project/member-of/${id}/maps`);
  return data.map((projectAndMap) => ({
    ...projectAndMap.project,
    map: projectAndMap.map,
    course: projectAndMap.course,
  }));
};

export const replaceProjectDefaultMap = async ({
  id,
  defaultMapStorageUrl,
}) => {
  const { data } = await axios.post(`project/${id}/replace-map`, {
    defaultMapStorageUrl,
  });
  return data;
};

export const setProjectPossibleRelationshipsTypes = async ({
  id,
  relationshipsCsvStorageUrl,
}) => {
  const { data } = await axios.post(
    `project/${id}/set-possible-relationships`,
    {
      relationshipsCsvStorageUrl,
    },
  );
  return data;
};

export const setProjectPossibleRelationshipsTypesColors = async ({
  id,
  colors,
}) => {
  const { data } = await axios.post(
    `project/${id}/set-possible-relationships-colors`,
    {
      colors,
    },
  );
  return data;
};

export const setProjectPossibleConcepts = async ({
  id,
  conceptsCsvStorageUrl,
}) => {
  const { data } = await axios.post(`project/${id}/set-possible-concepts`, {
    conceptsCsvStorageUrl,
  });
  return data;
};

export const deleteProjectPossibleConcepts = async ({
  id,
  conceptsToDelete,
}) => {
  await axios.post(`project/${id}/set-possible-concepts/delete`, {
    conceptsToDelete,
  });
};

export const deleteProjectPossibleRelationships = async ({
  id,
  relationshipsToDelete,
}) => {
  await axios.post(`project/${id}/set-possible-relationships/delete`, {
    relationshipsToDelete,
  });
};

export const mergeProjectStudentsMaps = async (id) => {
  await axios.post(`project/${id}/merge-maps`);
};

export const calculateMergeComplexity = async (id) => {
  return axios.get(`project/${id}/calc-merge-maps`);
};

export const removeStudentFromProject = async (projectId, studentId) => {
  await axios.delete(`project/${projectId}/students/${studentId}`);
};

export const getStudentScores = async (project, snapshots) => {
  const { data } = await axios.post(`project/${project.id}/scores`, {
    snapshotIds: snapshots.map((snapshot) => snapshot.id),
  });
  return data;
};

export const downloadAllStudentsRelationships = async (
  projectId,
  snapshotId,
) => {
  const { data } = await axios.post(`project/${projectId}/download-all`, {
    snapshotId,
  });
  return data;
};
