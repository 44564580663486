import Switch from "rc-switch";
import "./style.css";

const Toggle = ({ checked, checkedLabel, unCheckedLabel, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={(newChecked) => onChange(newChecked)}
      checkedChildren={checkedLabel}
      unCheckedChildren={unCheckedLabel}
    />
  );
};

export default Toggle;
