import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { exportMap } from "../../../../../api/map";
import {
  getStudentScores,
  mergeProjectStudentsMaps,
  removeStudentFromProject as apiRemoveStudentFromProject,
} from "../../../../../api/project";
import ConfirmationPopup from "../../../../shared/confirmation-popup/ConfirmationPopup";
import TextInput from "../../../../shared/text-input/TextInput";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import { STUDENT_VIEW_MODE } from "../../../../../utils/student-view-mode";
import SnapshotSelector from "../../snapshot/SnapshotSelector";
import Pusher from "pusher-js";
import DownloadProgress from "../../snapshot/DownloadProgress";
import { usePusher } from "../../../../../context/PusherContext";

const StudentsPanel = ({ students, project, onMerge, onRemoveStudent }) => {
  const navigate = useNavigate();
  const [merging, setMerging] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [studentIdToRemove, setStudentIdToRemove] = useState(null);
  const [deletingStudent, setDeletingStudent] = useState(false);
  const [studentRemoveVisible, setStudentRemoveVisible] = useState(false);
  const [snapshotSelectorVisible, setSnapshotSelectorVisible] = useState(false);
  const [progressText, setProgressText] = useState("");
  const [comparingSnapshotsVisible, setComparingSnapshotsVisible] =
    useState(false);
  const [showMergeConfirmationPopup, setShowMergeConfirmationPopup] =
    useState(false);
  const pusher = usePusher();

  useEffect(() => {
    const channel = pusher.subscribe("grading");

    channel.bind("compare-snapshots", function (data) {
      setProgressText(data.message);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const mergeAll = async () => {
    try {
      setMerging(true);
      await mergeProjectStudentsMaps(project.id);
      onMerge();
    } catch (e) {
      console.log(e);
    } finally {
      setMerging(false);
    }
  };

  const exportAll = (grading = false) => {
    const projectName = project?.name?.replace(/\s+/g, "-").toLowerCase();

    students.forEach((studentMap) => {
      exportMap(
        studentMap.map.id,
        `${projectName}__${studentMap.student.email}-map`,
        grading,
      );
    });
  };

  const onStudentClick = (student) => {
    navigate(`/project/${project.id}/${student.firebaseUid}`, {
      viewMode: STUDENT_VIEW_MODE.STUDENT,
    });
  };

  const openStudentRemovePopup = (studentId) => {
    setStudentIdToRemove(studentId);
    setStudentRemoveVisible(true);
  };

  const removeStudentFromProject = async () => {
    setDeletingStudent(true);
    try {
      await apiRemoveStudentFromProject(project.id, studentIdToRemove);
      await onRemoveStudent();
      setStudentRemoveVisible(false);
      setStudentIdToRemove(null);
    } catch (e) {
      console.log(e);
    } finally {
      setDeletingStudent(false);
    }
  };

  const filteredStudents = students
    .filter(
      (student) =>
        student.student.name.toLowerCase().indexOf(searchInput.toLowerCase()) >
          -1 ||
        student.student.email.toLowerCase().indexOf(searchInput.toLowerCase()) >
          -1,
    )
    ?.sort((a, b) => a.student.name.localeCompare(b.student.name));

  const onSnapshotsSelected = async (snapshots) => {
    setSnapshotSelectorVisible(false);
    setComparingSnapshotsVisible(true);
    await getStudentScores(project, snapshots);
  };

  const studentsList = () => {
    return (
      <>
        <TextInput
          placeholder="Search student... (Email or Name)"
          value={searchInput}
          onChange={setSearchInput}
          classes="mt-2"
        />
        <div className="divide-y flex-1 overflow-y-scroll mt-3">
          {filteredStudents.length === 0 && (
            <div className="flex items-center justify-center text-gray-600 mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 mr-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                />
              </svg>
              <span className="text-sm">No students found for this search</span>
            </div>
          )}
          {filteredStudents.map((student) => (
            <div
              onClick={() => onStudentClick(student.student)}
              key={`${student.student.email}_${student.map.id}`}
              className="py-2 px-2 text-sm flex
              items-center
              justify-between cursor-pointer hover:bg-[#e5e5e5]"
            >
              <div className={`flex flex-col`}>
                <div>
                  <span>{student.student.email}</span>
                </div>
                <div>
                  <span className={`text-[12px] text-gray-400`}>
                    {student.student.name}
                  </span>
                </div>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openStudentRemovePopup(student.student.id);
                }}
                className={`cursor-pointer`}
              >
                <svg
                  className={`w-4 h-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <Transition appear show={studentRemoveVisible} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setStudentRemoveVisible(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              <div
                                className={`flex flex-1 items-center justify-between`}
                              >
                                Remove student from project?
                                <span
                                  className={`cursor-pointer`}
                                  onClick={() => setStudentRemoveVisible(false)}
                                >
                                  <AiOutlineClose />
                                </span>
                              </div>
                            </Dialog.Title>
                            <div>
                              <br />
                              {!deletingStudent && (
                                <div className={`flex gap-2`}>
                                  <button
                                    onClick={removeStudentFromProject}
                                    type="submit"
                                    className="flex justify-center mr-2 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Remove
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setStudentRemoveVisible(false)
                                    }
                                    className="bg-white hover:bg-gray-100 text-gray-800 text-sm font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                              {deletingStudent && (
                                <div className={`flex gap-2`}>Removing...</div>
                              )}
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </span>
            </div>
          ))}
        </div>
        {students.length > 0 && (
          <div className="pt-4">
            <button
              disabled={project.mergedMap}
              onClick={() => setShowMergeConfirmationPopup(true)}
              className={`${
                project.mergedMap && "disabled:opacity-50"
              } text-indigo-600 mb-3 flex items-center justify-center group relative flex w-full justify-center rounded-md border border-indigo-600 py-2 px-4 text-sm font-medium hover:bg-[#f8f8ff] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-3 text-[indigo-600]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                />
              </svg>
              Merge all students maps
            </button>
            {comparingSnapshotsVisible && (
              <DownloadProgress
                text={progressText}
                visible={comparingSnapshotsVisible}
                setVisible={setComparingSnapshotsVisible}
              />
            )}
            <SnapshotSelector
              onSelected={onSnapshotsSelected}
              project={project}
              multi={true}
              visible={snapshotSelectorVisible}
              setVisible={setSnapshotSelectorVisible}
            />
            <button
              onClick={() => setSnapshotSelectorVisible(true)}
              className="text-indigo-600 mb-3 flex items-center justify-center group relative flex w-full justify-center rounded-md border border-indigo-600 py-2 px-4 text-sm font-medium hover:bg-[#f8f8ff] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-3 color-[indigo-600]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              <span>Compare Snapshots (for grading)</span>
            </button>
            <button
              onClick={() => exportAll()}
              className="mb-4 flex items-center justify-center group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-3 color-[indigo-600]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Export all assignments
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="py-5 px-7 flex flex-col justify-between basis-1/4 bg-white shadow">
      <span className="font-bold text-xl mb-2">
        Students in this project ({students?.length})
      </span>
      {students.length > 0 ? (
        studentsList()
      ) : (
        <div className="flex-1">
          <div className="flex items-center justify-center text-gray-600 mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
              />
            </svg>
            <span className="text-sm flex-1">
              No students joined yet. Students can join with the project ID.
            </span>
          </div>
        </div>
      )}
      {showMergeConfirmationPopup && (
        <ConfirmationPopup
          width={400}
          show={showMergeConfirmationPopup}
          onConfirm={() => {
            setShowMergeConfirmationPopup(false);
            mergeAll();
          }}
          confirmText={merging ? "Merging..." : "Merge All"}
          content={
            <span>
              <p className="font-bold text-center mb-2">Are you sure?</p> This
              will create a new map for each student in this project with the
              union of the maps from all students in this project.
            </span>
          }
          onClose={() => setShowMergeConfirmationPopup(false)}
          onReject={() => setShowMergeConfirmationPopup(false)}
        />
      )}
    </div>
  );
};

export default StudentsPanel;
