import {Fragment, useEffect, useRef, useState} from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const ComboboxComponent = ({
  placeholder,
  options,
  selectedValue,
  onSelect,
  onType,
  allowNew = true,
  autofocus = false,
  classes = "",
  wrapperClasses = "",
  inputClasses = "",
}) => {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((item) =>
          item.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  const onSelectOption = (option) => {
    const isNew =
      options.findIndex((currOption) => currOption.id === option.id) === -1;
    onSelect(option, isNew);
  };

  useEffect(() => {
    onType && onType(query);
  }, [query]);

  const buttonRef = useRef();

  return (
    <Combobox value={selectedValue} onChange={onSelectOption}>
      <div className={`relative mt-1 z-20 ${classes}`}>
        <div
          className={`relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm ${wrapperClasses}`}
        >
          <Combobox.Input
            autoFocus={autofocus}
            className={`w-full grow appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${inputClasses}`}
            displayValue={(option) => option.label}
            placeholder={placeholder}
            onChange={(event) => setQuery(event.target.value)}
            onClick={() => buttonRef.current?.click()}
          />
          <Combobox.Button ref={buttonRef} className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {allowNew && query.length > 0 && (
              <Combobox.Option
                value={{ id: null, name: query }}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  }`
                }
              >
                Create "{query}"
              </Combobox.Option>
            )}
            {filteredOptions.map((item) => (
              <Combobox.Option
                key={item.id}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  }`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {item.label}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                          active ? "text-white" : "text-indigo-600"
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default ComboboxComponent;
