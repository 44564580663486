import Popup from "../Popup";

const ConfirmationPopup = ({
  show,
  onClose,
  content = "Are you sure?",
  onConfirm,
  onReject,
  confirmText = "Yes",
  rejectText = "Cancel",
  width = 320,
}) => {
  return (
    <Popup
      width={width}
      contentComponent={
        <div className="flex">
          <span className="font-medium">{content}</span>
        </div>
      }
      footerComponent={
        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            type="submit"
            className="flex justify-center mr-2 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {confirmText}
          </button>
          <button
            type="button"
            onClick={onReject}
            className="bg-white hover:bg-gray-100 text-gray-800 text-sm font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          >
            {rejectText}
          </button>
        </div>
      }
      onSubmit={onConfirm}
      onCancel={onReject}
      show={show}
      onClose={onClose}
    />
  );
};

export default ConfirmationPopup;
