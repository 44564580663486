import { AiOutlineClose } from "react-icons/ai";
import React, { Fragment, useEffect, useState } from "react";
import { ROLES } from "../../../../utils/entities";
import { getSnapshots } from "../../../../api/project";
import { Dialog, Transition } from "@headlessui/react";
import { useUserAuth } from "../../../../context/userAuthContext";
import { Button, ListGroup } from "flowbite-react";
import { Checkbox } from "flowbite-react";

const SnapshotSelector = ({
  project,
  visible,
  setVisible,
  onSelected,
  multi,
}) => {
  const { user } = useUserAuth();
  const [snapshots, setSnapshots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const loadSnapshots = async (projectId) => {
    try {
      setLoading(true);
      const data = await getSnapshots(projectId);
      setSnapshots(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project) loadSnapshots(project.id).then();
  }, [project.id, visible]);

  if (!project || user.role !== ROLES.TEACHER) return null;

  const handleCheckboxChange = (value) => {
    if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value),
      );
    } else {
      if (selectedCheckboxes.length < 2)
        setSelectedCheckboxes([...selectedCheckboxes, value]);
    }
  };

  const onMultiSelect = () => {
    let selectedSnapshots = [];

    if (selectedCheckboxes.length === 1) {
      selectedSnapshots = [{ id: undefined }, snapshots[0]];
    } else {
      selectedSnapshots = snapshots.filter((snapshot) =>
        selectedCheckboxes.includes(snapshot.id),
      );
    }

    onSelected(selectedSnapshots);
  };

  const firstSnapshotSelected =
    snapshots?.findIndex((item) => item.id === selectedCheckboxes[0]) === 0;

  const selectEnabled =
    selectedCheckboxes.length === 2 ||
    (selectedCheckboxes.length === 1 && firstSnapshotSelected);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setVisible(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[700px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className={`flex flex-1 items-center justify-between`}>
                    Snapshots
                    <span
                      className={`cursor-pointer`}
                      onClick={() => setVisible(false)}
                    >
                      <AiOutlineClose />
                    </span>
                  </div>
                </Dialog.Title>
                {loading && (
                  <div className="py-3 accent-gray-300">Loading...</div>
                )}
                {!loading && snapshots.length > 0 && (
                  <div className={`mt-4`}>
                    <ListGroup className="mb-4">
                      {snapshots?.map((snapshot) => (
                        <ListGroup.Item
                          onClick={() => {
                            if (!multi) onSelected(snapshot);
                          }}
                        >
                          <div className={`flex justify-between flex-1`}>
                            <span
                              className={`flex gap-2 items-center text-left`}
                            >
                              {multi && (
                                <Checkbox
                                  disabled={
                                    selectedCheckboxes.length >= 2 &&
                                    !selectedCheckboxes.includes(snapshot.id)
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(snapshot.id)
                                  }
                                  checked={selectedCheckboxes.includes(
                                    snapshot.id,
                                  )}
                                />
                              )}
                              {snapshot.name}
                            </span>
                            <div className={`accent-gray-500`}>
                              <div className={`text-[12px] text-gray-500`}>
                                {new Date(snapshot.createdAt).toLocaleString()}
                              </div>
                              <div className="text-[12px] text-purple-500">
                                ({snapshot.studentCount} students)
                              </div>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {multi && (
                      <div>
                        <Button
                          onClick={onMultiSelect}
                          disabled={!selectEnabled}
                          color="blue"
                        >
                          Select
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {!loading && snapshots.length === 0 && (
                  <div className="mt-2 accent-gray-400">
                    No snapshots available
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SnapshotSelector;
