import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useRecoilValue } from "recoil";
import { getValueInfoByTitle } from "../../../../../api/wikipedia-api";
import { projectState } from "../../../../../store/project";
import Button from "../../../button/Button";
import Spinner from "../../../spinner/Spinner";
import WikipediaAutocomplete from "../../../wikipedia-autocomplete/WikipediaAutocomplete";
import WikipediaValue from "../wikipedia-value/WikipediaValue";

const CreateConceptDialog = ({
  onClose,
  onCreate,
  position,
  skipValues = [],
}) => {
  const project = useRecoilValue(projectState);

  const [selectedWikipediaValue, setSelectedWikipediaValue] = useState();
  const [additionalValueInfo, setAdditionalValueInfo] = useState();
  const [loading, setLoading] = useState(false);

  const allowValues = project.possibleConcepts || [];

  const fetchAdditionalInfo = async (selectedValue) => {
    setLoading(true);
    const info = await getValueInfoByTitle(selectedValue);
    setAdditionalValueInfo(info);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedWikipediaValue && selectedWikipediaValue !== "") {
      fetchAdditionalInfo(selectedWikipediaValue?.label);
    }
  }, [selectedWikipediaValue]);

  const create = () => {
    onCreate({ ...selectedWikipediaValue, ...additionalValueInfo }, position);
    onClose();
  };

  return (
    <Draggable bounds="parent">
      <div
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
        className="absolute rounded-md w-[400px] shadow z-20 flex flex-col bg-white p-3 cursor-grab"
      >
        <div className="flex justify-between items-center mb-3">
          <span className="font-bold">Add Concept</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
            onClick={onClose}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <WikipediaAutocomplete
          onSelectValue={setSelectedWikipediaValue}
          skipValues={skipValues}
          allowValues={allowValues}
        />

        {(loading || additionalValueInfo) && (
          <div className="pt-3 flex flex-col relative min-h-[100px]">
            {loading && <Spinner />}
            {additionalValueInfo && (
              <>
                {" "}
                <WikipediaValue
                  classes="mb-3"
                  wikipediaValue={additionalValueInfo}
                  showWikipedialink={false}
                />
                <Button label="Add Concept" onClick={create} />
              </>
            )}
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default CreateConceptDialog;
