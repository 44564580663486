import ComboboxComponent from "../../combobox/Combobox";
import Slider from "../../slider/Slider";
import Toggle from "../../switch/Switch";
import { throttle } from "lodash";
import {useRecoilState} from "recoil";
import {zoomState} from "../../../../store/project";

const ActionsPanel = ({
                        onExport,
                        onShowAll,
                        onExpandAll,
                        onEditChange,
                        search,
                        editable = true,
                        onShowCitationsChange,
                        showCitationsModeOn,
                        editModeOn,
                        onZoomChange,
                        onShowLayoutDialog,
                        defaultZoom
                      }) => {

    const onZoomSliderChangeThrottle = throttle(onZoomChange, 10);
    const [zoom, setZoom] = useRecoilState(zoomState);

    return (
        <div onClick={(e) => {
            e.stopPropagation()
        }
        } className="absolute z-10 top-5 flex items-center justify-between px-5 w-full">
            <div className="flex items-center gap-3">
                <button
                    onClick={onExport}
                    className="bg-white flex items-center justify-center hover:bg-gray-100 text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                    </svg>
                </button>
                {editable && (
                    <Toggle
                        checked={editModeOn}
                        checkedLabel="Edit"
                        unCheckedLabel={"View"}
                        onChange={onEditChange}
                    />
                )}
                <button
                    onClick={onShowAll}
                    className="bg-white flex items-center justify-center hover:bg-gray-100 text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>
                </button>
                <button
                    onClick={onExpandAll}
                    className="bg-white flex items-center justify-center hover:bg-gray-100 text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                        />
                    </svg>
                </button>
                {onShowCitationsChange && (
                    <div
                        onClick={() => onShowCitationsChange(!showCitationsModeOn)}
                        className="cursor-pointer select-none bg-white flex items-center justify-center text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
                    >
                        <input
                            id="editable"
                            type="checkbox"
                            defaultChecked={showCitationsModeOn}
                            className="rounded checked w-4 h-4 ml-1"
                        />
                        <span className="font-medium ml-2 text-sm pr-1">
              Show citations
            </span>
                    </div>
                )}
                {search && search.searchOptions && (
                    <div
                        className={`${
                            search.searchModeOn ? "w-[225px]" : "w-[38px]"
                        } transition-all ease-in-out select-none bg-white flex items-stretch text-sm text-gray-800 font-semibold border border-[#e5e7eb] rounded-lg`}
                    >
                        <div
                            className="pr-2 pl-2 py-2 cursor-pointer"
                            onClick={search.onSearchModeChange}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                />
                            </svg>
                        </div>
                        {search.searchModeOn && (
                            <ComboboxComponent
                                placeholder="Search concept.."
                                options={search.searchOptions}
                                onSelect={(selectedConcept) =>
                                    search.onSearchSelect(selectedConcept)
                                }
                                allowNew={false}
                                autofocus
                                classes={`mt-0 ${search.searchModeOn ? "block" : "hidden"} flex-1`}
                                inputClasses="border-0 rounded-lg py-0 h-full pl-0 font-medium !border-0 !focus:border-0 !focus:border-white border-transparent focus:border-transparent focus:ring-0"
                                wrapperClasses="bg-transparent h-full"
                            />
                        )}
                    </div>
                )}
                <div
                    onClick={onShowLayoutDialog}
                    className="cursor-pointer select-none bg-white flex items-center justify-center text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
                >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1569683635191"
                         viewBox="0 0 1024 1024" version="1.1" height="1.3em" width="1.3em"
                         xmlns="http://www.w3.org/2000/svg">
                        <defs></defs>
                        <path
                            d="M843.5 737.4c-12.4-75.2-79.2-129.1-155.3-125.4S550.9 676 546 752c-153.5-4.8-208-40.7-199.1-113.7 3.3-27.3 19.8-41.9 50.1-49 18.4-4.3 38.8-4.9 57.3-3.2 1.7 0.2 3.5 0.3 5.2 0.5 11.3 2.7 22.8 5 34.3 6.8 34.1 5.6 68.8 8.4 101.8 6.6 92.8-5 156-45.9 159.2-132.7 3.1-84.1-54.7-143.7-147.9-183.6-29.9-12.8-61.6-22.7-93.3-30.2-14.3-3.4-26.3-5.7-35.2-7.2-7.9-75.9-71.5-133.8-147.8-134.4-76.3-0.6-140.9 56.1-150.1 131.9s40 146.3 114.2 163.9c74.2 17.6 149.9-23.3 175.7-95.1 9.4 1.7 18.7 3.6 28 5.8 28.2 6.6 56.4 15.4 82.4 26.6 70.7 30.2 109.3 70.1 107.5 119.9-1.6 44.6-33.6 65.2-96.2 68.6-27.5 1.5-57.6-0.9-87.3-5.8-8.3-1.4-15.9-2.8-22.6-4.3-3.9-0.8-6.6-1.5-7.8-1.8l-3.1-0.6c-2.2-0.3-5.9-0.8-10.7-1.3-25-2.3-52.1-1.5-78.5 4.6-55.2 12.9-93.9 47.2-101.1 105.8-15.7 126.2 78.6 184.7 276 188.9 29.1 70.4 106.4 107.9 179.6 87 73.3-20.9 119.3-93.4 106.9-168.6zM329.1 345.2c-46 0-83.3-37.3-83.3-83.3s37.3-83.3 83.3-83.3 83.3 37.3 83.3 83.3-37.3 83.3-83.3 83.3zM695.6 845c-46 0-83.3-37.3-83.3-83.3s37.3-83.3 83.3-83.3 83.3 37.3 83.3 83.3-37.3 83.3-83.3 83.3z"></path>
                    </svg>
                </div>
            </div>
            <Slider value={zoom} defaultValue={1} onChange={(e) => {
                onZoomSliderChangeThrottle(e.target.value);
                setZoom(parseFloat(e.target.value));
            }} style={{maxWidth: '200px'}}/>
        </div>
    );
};

export default ActionsPanel;
