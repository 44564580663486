import {
  signOut,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase-config";
import {getUser} from "../api/user";

const userAuthContext = createContext(undefined);

export function UserAuthContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        getUser().then(usr => {
          setUser(usr);
          setLoading(false);
        });
      } else {
        setLoading(false)
      }
    });

    return () => unsubscribe();
  }, []);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function checkIfEmailExists(email) {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      return methods.length > 0;
    } catch (err) {
      console.log(err);
    }
  }

  async function sendSignInLink(email) {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_CLIENT_URL}/signup?email_verified=${email}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    } catch (err) {
      console.log(err);
    }
  }

  function isAfterEmailVerification() {
    return isSignInWithEmailLink(auth, window.location.href);
  }

  function sendResetPasswordEmail(email) {
    const actionCodeSettings = {
      url: process.env.REACT_APP_CLIENT_URL,
      handleCodeInApp: true,
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  }

  function updateUser(user, data) {
    return updateProfile(user, data);
  }

  function logOut() {
    setUser(undefined);
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  return (
    <userAuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        sendSignInLink,
        isAfterEmailVerification,
        sendResetPasswordEmail,
        checkIfEmailExists
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
