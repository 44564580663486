import {
  STUDENT_VIEW_MODE,
} from "../../../utils/student-view-mode";
import { useRecoilState } from "recoil";
import NavBar from "../home/nav-bar/NavBar";
import { useParams } from "react-router-dom";
import { ROLES } from "../../../utils/entities";
import Spinner from "../../shared/spinner/Spinner";
import {Fragment, useEffect, useState} from "react";
import { projectState } from "../../../store/project";
import {useUserAuth} from "../../../context/userAuthContext";
import TeacherProject from "./teacher-project/TeacherProject";
import StudentProject from "./student-project/StudentProject";
import { getProjectById, getProjectStudents} from "../../../api/project";
import ProjectHeader from "./header/ProjectHeader";

const Project = () => {
  const { user } = useUserAuth();
  const { projectId } = useParams();
  const { studentId } = useParams();
  const [project, setProject] = useRecoilState(projectState);

  const [students, setStudents] = useState([]);
  const [studentViewMode] = useState(
    STUDENT_VIEW_MODE.STUDENT
  );

  const fetchProject = async () => {
    const project = await getProjectById(projectId);
    const students = await getProjectStudents(projectId);
    setProject(project);
    setStudents(students);
  };

  const getView = () => {
    if (user?.role === ROLES.TEACHER) {
      if (studentId) {
        return <StudentProject project={project}
                               studentUid={studentId} />;
      }
      return (
        <TeacherProject
          project={project}
          students={students}
          fetchProject={fetchProject}
          onRemoveStudent={fetchProject}
        />
      );
    } else {
      return (
        <StudentProject
          project={project}
          studentUid={user.firebaseUid}
          viewMode={studentViewMode}
          allowEdit={studentViewMode === STUDENT_VIEW_MODE.STUDENT}
        />
      );
    }
  };

  useEffect(() => {
    setProject(undefined);
    fetchProject().then();
  }, []);

  return (
    <div className="min-h-full min-w-full flex flex-col">
      <NavBar />
      <div className="flex flex-col flex-1">
        {project ? (
          <>
            <ProjectHeader />
            {project && getView()}
          </>
        ) : (
          <Spinner text="Loading project..." />
        )}
      </div>
    </div>
  );
};

export default Project;
