import { AgGridReact } from "ag-grid-react";
import {AiOutlineClose} from "react-icons/ai";
import "ag-grid-community/styles/ag-grid.css";
import {Dialog, Transition} from "@headlessui/react";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {calculateMergeComplexity} from "../../../../../api/project";
import {useUserAuth} from "../../../../../context/userAuthContext";
import {ROLES} from "../../../../../utils/entities";

const MergeCost = ({ projectId, visible, setVisibility }) => {
    const { user } = useUserAuth();
    const [counts, setCounts] = useState([]);
    const gridRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const onGridReady = params => {
        gridRef.current = params.api;
    };

    const [columnDefs] = useState([
        {field: "relationship", sortable: true, filter: true, width: 600,},
        {field: "count", sortable: true, filter: true, flex: 1},
    ]);

    const fetchCounts = async () => {
        try {
            setLoading(true);
            const { data } = await calculateMergeComplexity(projectId);
            const formatted = data.filteredAndSortedOccurrences.map(([key, value]) => ({
                relationship: key,
                count: value
            }));

            setCounts(formatted);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!projectId || !visible) return;
        fetchCounts().then();
    }, [visible]);

    if (user.role !== ROLES.TEACHER) return null;

    return <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setVisibility(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-25"/>
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel
                            className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                <div className={`flex flex-1 items-center justify-between`}>
                                    Relationship Counts (3 and more)
                                    <span className={`cursor-pointer`} onClick={() => setVisibility(false)}>
                                      <AiOutlineClose/>
                                      </span>
                                </div>
                            </Dialog.Title>
                            <div>
                                {loading && <div className={`flex flex-1 items-center justify-center h-[200px]`}>
                                    Loading...
                                </div>}
                                <br />
                                {!loading && <div className="ag-theme-alpine" style={{height: 300, width: '100%'}}>
                                    <AgGridReact autoHeight={true}
                                                 onGridReady={onGridReady}
                                                 rowData={counts}
                                                 columnDefs={columnDefs}/>
                                    <br />
                                </div>}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
};

export default MergeCost;



