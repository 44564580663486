import { useState } from "react";
import { useUserAuth } from "../../../../context/userAuthContext";
import Popup from "../../../shared/Popup";

const ForgetPasswordPopup = ({ show, onClose }) => {
  const { sendResetPasswordEmail } = useUserAuth();

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const sendResetEmail = async () => {
    try {
      await sendResetPasswordEmail(email);
    } catch (e) {
      setError("That doesn’t appear to be a valid email address.");
    } finally {
        onClose();
    }
  };

  return (
    <Popup
      title="Reset Password"
      width={400}
      content={
        <div>
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
              className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Email address"
            />
          </div>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mt-5 rounded relative"
              role="alert"
            >
              <span className="block sm:inline text-sm">{error}</span>
            </div>
          )}
        </div>
      }
      onSubmit={sendResetEmail}
      onCancel={onClose}
      submitText="Send password reset email"
      show={show}
      onClose={onClose}
    />
  );
};

export default ForgetPasswordPopup;
