import { useEffect, useState } from "react";
import {
  getUrlByTitle,
  getWikipediaValuesByText,
} from "../../../api/wikipedia-api";
import ComboboxComponent from "../combobox/Combobox";

const WikipediaAutocomplete = ({
  onSelectValue,
  defaultValue,
  skipValues = [],
  allowValues = [],
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [query, setQuery] = useState();
  const [wikipediaValues, setWikipediaValues] = useState([]);

  const getWikipediaValues = async (text) => {
    const values = await getWikipediaValuesByText(text);
    let filteredValues = values;

    if (allowValues.length > 0) {
      const allowValuesLower = allowValues.map((value) => value.toLowerCase());
      filteredValues = values.filter((value) =>
        allowValuesLower.includes(value.label.toLowerCase())
      );

      // add the allowed values list, in case they don't exist in Wikipedia
      // this allows the user to still add a concept if it doesn't exist in Wikipedia
      filteredValues = filteredValues.concat(allowValues.map((value) => ({
        id: Math.round(Math.random() * 100000000),
        label: value,
        url: null,
      })));

    } else if (skipValues.length > 0) {
      filteredValues = values.filter(
        (value) => !skipValues.includes(value.label)
      );
    }

    const sorted = filteredValues.sort((a, b) => {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    });

    setWikipediaValues(sorted);
  };

  useEffect(() => {
    if (query !== "") {
      getWikipediaValues(query);
    } else {
      setWikipediaValues([]);
    }
  }, [query]);

  useEffect(() => {
    onSelectValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue);
      setSelectedValue({
        id: 1111,
        label: defaultValue,
        url: getUrlByTitle(defaultValue),
      });
    }
  }, [defaultValue]);

  return (
    <ComboboxComponent
      placeholder="Search a wikipedia value.."
      options={wikipediaValues}
      onSelect={setSelectedValue}
      selectedValue={selectedValue}
      onType={setQuery}
      allowNew={false}
      autofocus
    />
  );
};

export default WikipediaAutocomplete;
