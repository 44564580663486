import NavBar from "../nav-bar/NavBar";
import ProjectList from "../project-list/ProjectList";
import {useUserAuth} from "../../../../context/userAuthContext";

const TeacherHome = () => {
  const { user } = useUserAuth();

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="min-h-full flex flex-col">
        <header className="bg-white shadow">
          <div className="flex items-center gap-2 flex-wrap px-4 py-4">
            <span className={`ml-2 text-md text-gray-400 text-regular uppercase`}>
              Teacher:
            </span>
            <span className="text-md tracking-tight text-gray-900">
              {user.name}
            </span>
          </div>
        </header>
        <ProjectList/>
      </div>
    </div>
  );
};

export default TeacherHome;
