export const styleSheet =  (project) => [
  {
    selector: "node",
    style: {
      "background-color": (node) => {
        const isOriginTeacher = node.data("origin");
        return isOriginTeacher === 'teacher' ? "#e72c35" : 'darkorange'
      },
      width: "label",
      height: "label",
      "text-wrap": "wrap",
      "text-max-width": 80,
      // a single "padding" is not supported in the types :(
      "padding-top": "4",
      "padding-bottom": "4",
      "padding-left": "4",
      "padding-right": "4",
      // this fixes the text being shifted down on nodes (sadly no fix for edges, but it's not as obvious there without borders)
      shape: "round-rectangle",
      display: (node) => {
        const isVisible = node.data("visible");
        return isVisible ? "element" : "none";
      },
    },
  },
  {
    selector: "node:selected",
    style: {
      "background-color": (node) => {
        const isOriginTeacher = node.data("origin");
        return isOriginTeacher === 'teacher' ? "#e72c35" : 'orange'
      },
    },
  },
  {
    selector: "node[label]",
    style: {
      label: (node) => {
        const label = node.data("label");
        if (label.length > 30) return `${label.slice(0, 30)}...`;
        return label;
      },
      "font-size": "15",
      color: "white",
      "text-halign": "center",
      "text-valign": "center",
    },
  },
  {
    selector: "edge",
    style: {
      "line-color": (edge) => {
        const colors = project?.possibleRelationshipsTypesColors ?? {};
        // check if the relationship has a defined color and use it.
        const color = colors[edge.data("label")];

        // we no longer have the teacher vs. student color logic
        // keeping here in case we want to bring it back
        const isOriginTeacher = edge.data("origin");
        return color; //isOriginTeacher === 'teacher' ? color : 'darkorange'
      },
      "curve-style": "bezier",
      "target-arrow-shape": "triangle",
      width: (edge) => ((edge.data("citations") || []).length > 1 ? 3 : 1.5),
    },
  },
];
