import { getUrlByTitle } from "../../../../../api/wikipedia-api";

const WikipediaValue = ({ wikipediaValue,
                          classes = "",
                          showWikipedialink = true,
                          onClose,
                          showCloseButton = false }) => {
  const goToWikipediaLink = () => {
    window
      .open(getUrlByTitle(wikipediaValue.label), "_blank")
      .focus();
  };

  const linkChip = (
    <button
      className="rounded-md ml-2 py-1 px-2 bg-[#ebebeb] transition flex items-center text-sm text-indigo-600 w-fit max-w-[210px] hover:bg-[#dbdbdb]"
      onClick={goToWikipediaLink}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4 mr-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
        />
      </svg>
      Wiki
    </button>
  );

  return (
    <div className={`flex flex-col py-1 px-1 ${classes}`}>
      <div className="flex">
        <img
          className="object-cover flex-1 min-h-[100px] min-w-[100px] max-w-[100px] rounded-l shadow mr-4"
          src={
            wikipediaValue.image ||
            "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
          }
          alt="wikipedia value"
        />
        <div className="flex flex-col">
          <div className="flex justify-between items-start mb-2">
            <span className="font-bold">{wikipediaValue.label}</span>
            {showWikipedialink && linkChip}
          </div>
          <span className="text-sm">{wikipediaValue.description}</span>
        </div>
        { showCloseButton && <div className={`ml-auto`}>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={onClose}
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        }
      </div>
    </div>
  );
};

export default WikipediaValue;
