import {useSetRecoilState} from "recoil";
import {AiOutlineClose} from "react-icons/ai";
import {ROLES} from "../../../../../utils/entities";
import {Dialog, Transition} from "@headlessui/react";
import {projectState} from "../../../../../store/project";
import React, {Fragment, useEffect, useState} from "react";
import {useUserAuth} from "../../../../../context/userAuthContext";
import {editProject, getProjectById} from "../../../../../api/project";

const ProjectSettings = ({ project, visible, setVisibility }) => {
    const { user } = useUserAuth();
    const [saving, setSaving] = useState(false);
    const [projectDraft, setProjectDraft] = useState();
    const setProject = useSetRecoilState(projectState);

    useEffect(() => {
        setProjectDraft(project);
    }, [project]);

    if (user.role !== ROLES.TEACHER) return null;
    if (!project || !projectDraft) return null;

    const save = async () => {
        const { id, editable, requireCitations, emailSuffix } = projectDraft;

        try {
            setSaving(true);
            await editProject(id, {
                editable,
                requireCitations,
                emailSuffix
            });

            const editedProject = await getProjectById(id);
            setProject(editedProject);
        } catch(error) {
            console.log(error);
        } finally {
            setSaving(false);
        }
        setVisibility(false);
    };

    return <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setVisibility(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25"/>
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                <div className={`flex flex-1 items-center justify-between`}>
                                    Project Settings
                                    <span className={`cursor-pointer`} onClick={() => setVisibility(false)}>
                      <AiOutlineClose/>
                      </span>
                                </div>
                            </Dialog.Title>
                            <div>
                                <br/>
                                <table className="min-w-full">
                                    <tbody>
                                    <tr>
                                        <td className="px-4 py-2">Editable</td>
                                        <td className="px-4 py-2">
                                            <select
                                                defaultValue={projectDraft.editable ? "yes" : "no"}
                                                onChange={(event) => setProjectDraft({ ...projectDraft, editable: event.target.value === "yes" })}
                                                className="form-select block w-full mt-1 border-gray-300 rounded-md shadow-sm">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">Require Citations</td>
                                        <td className="px-4 py-2">
                                            <select
                                                defaultValue={projectDraft.requireCitations ? "yes" : "no"}
                                                onChange={(event) => setProjectDraft({ ...projectDraft, requireCitations: event.target.value === "yes" })}
                                                className="form-select block w-full mt-1 border-gray-300 rounded-md shadow-sm">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            Email Domain Suffix
                                            <p className="text-xs text-gray-500 mt-1">Leave blank to allow any
                                                domain</p>
                                        </td>
                                        <td className="px-4 py-2">
                                            <input type="text" value={projectDraft.emailSuffix}
                                                onChange={(event) =>   setProjectDraft({ ...projectDraft, emailSuffix: event.target.value })}
                                                   className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                   placeholder="i.e: @uci.edu"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br/>
                            <div>
                                <button
                                    disabled={saving}
                                    onClick={save}
                                    type="submit"
                                    className={`${
                                        "disabled:opacity-50"
                                    } group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                >
                                    {saving ? "Saving..." : "Save"}
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
};

export default ProjectSettings;
