import NavBar from "../nav-bar/NavBar";
import ProjectList from "../project-list/ProjectList";
import {useUserAuth} from "../../../../context/userAuthContext";

const StudentHome = () => {
    const {user} = useUserAuth();

    return (
        <div className="min-h-full">
            <NavBar/>
            <div className="min-h-full flex flex-col">
                <header className="bg-white shadow">
                    <div className="flex items-center flex-wrap px-6 py-4">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                            {user.name} (Student)
                        </h1>
                    </div>
                </header>
                <ProjectList/>
            </div>
        </div>
    );
};

export default StudentHome;
