import { useMemo } from "react";
import Draggable from "react-draggable";
import { IoFilterCircleOutline, IoGitMergeOutline } from "react-icons/io5";
import { MdOutlineGraphicEq } from "react-icons/md";
import { SlGraph } from "react-icons/sl";
import {
  getAvergaeShortestPath,
  getConceptsDiff,
  getMeanDegree,
  getRelationshipsDiff,
} from "../utils/metrics";

const ConceptIcon = <IoFilterCircleOutline style={{ fontSize: "20px" }} />;
const RelationshipIcon = <IoGitMergeOutline style={{ fontSize: "20px" }} />;
const PathIcon = <SlGraph style={{ fontSize: "20px" }} />;
const GraphAvergaeIcon = <MdOutlineGraphicEq style={{ fontSize: "20px" }} />;

const Metric = ({ metric }) => {
  return (
    <div className="flex py-2 items-start">
      {metric.icon}
      <div className="flex ml-2 text-sm items-center">
        <span className="font-medium">{metric.name}:</span>
        <span className="text-sm ml-1">{metric.value}</span>
        {metric.additionalData && (
          <span className="text-sm ml-1">({metric.additionalData})</span>
        )}
      </div>
    </div>
  );
};

// currentGraphElements - cyto nodes and elements
// otherGraphElements - neo4j regular elememts

const MetricsDialog = ({
  currentGraphElements,
  comparedGraphElements,
  onClose,
}) => {
  const conceptsDiff = useMemo(() => {
    if (comparedGraphElements)
      return getConceptsDiff(
        currentGraphElements.nodes,
        comparedGraphElements.nodes
      );
    return {};
  }, [currentGraphElements, comparedGraphElements]);

  const relationshipsDiff = useMemo(() => {
    if (comparedGraphElements)
      return getRelationshipsDiff(currentGraphElements, comparedGraphElements);
    return {};
  }, [currentGraphElements, comparedGraphElements]);

  const currentGraph = [
    {
      name: "Average Shortest Path Length",
      value: getAvergaeShortestPath(
        currentGraphElements.elements,
        currentGraphElements.cyNodes
      ).toFixed(2),
      icon: PathIcon,
    },
    {
      name: "Average Node Degree",
      value: getMeanDegree(currentGraphElements.cyNodes).toFixed(1),
      icon: GraphAvergaeIcon,
    },
    ...(comparedGraphElements
      ? [
          {
            name: "Concepts",
            value: currentGraphElements.nodes.length,
            additionalData: (
              <span className="text-sm pt-1 px-1">
                <span className="text-green-700">
                  +{conceptsDiff.onlyFirstHas.length}
                </span>{" "}
                new |{" "}
                <span className="text-red-500">
                  -{conceptsDiff.onlySecondHas.length}
                </span>{" "}
                original
              </span>
            ),
            icon: ConceptIcon,
          },
          {
            name: "Relationships",
            value: currentGraphElements.edges.length,
            additionalData: (
              <span className="text-sm pt-1 px-1">
                <span className="text-green-700">
                  +{relationshipsDiff.onlyFirstHas.length}
                </span>{" "}
                new |{" "}
                <span className="text-red-500">
                  -{relationshipsDiff.onlySecondHas.length}
                </span>{" "}
                original
              </span>
            ),
            icon: RelationshipIcon,
          },
        ]
      : []),
  ];

  return (
    <Draggable bounds="parent">
      <div className="absolute rounded-md min-w-[300px] shadow z-20 flex flex-col divide-y bg-white p-4 pl-6 bottom-0 right-0 mr-2 mb-16 cursor-grab">
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center">
            <span className="font-bold">Metrics</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
            onClick={onClose}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="pt-3 divide-y">
          {currentGraph.map((metric) => (
            <Metric key={metric.name} metric={metric} />
          ))}
        </div>
      </div>
    </Draggable>
  );
};

export default MetricsDialog;
