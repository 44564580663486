const TextInput = ({
  id,
  name,
  value,
  onChange = () => {},
  required = false,
  placeholder,
  nativeProps = {},
  classes = "",
  disabled = false,
}) => {
  return (
    <input
      id={id}
      name={name}
      type={!disabled && "text"}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      disabled={disabled}
      {...(value && { value })}
      className={`relative block w-full appearance-none rounded-none rounded-r-md rounded-l-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${classes}`}
      placeholder={placeholder}
      {...nativeProps}
    />
  );
};

export default TextInput;
