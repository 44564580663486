import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useRecoilValue } from "recoil";
import { projectState } from "../../../../../store/project";
import Button from "../../../button/Button";
import ComboboxComponent from "../../../combobox/Combobox";
import ErrorBox from "../../../error-box/Errorbox";
import Spinner from "../../../spinner/Spinner";
import TextInput from "../../../text-input/TextInput";

const CreateRelationshipDialog = ({
  onClose,
  onCreate,
  onUpdate,
  onDelete,
  onClickOutside,
  position,
  concepts,
  relationship,
}) => {
  const project = useRecoilValue(projectState);

  const dialogRef = useRef();

  const [loading, setLoading] = useState(false);
  const [relationshipType, setRelationshipType] = useState();
  const [error, setError] = useState();
  const requireCitations = project.requireCitations;

  const origCitations = relationship?.data?.citation || relationship?.data?.citations[0];
  const [citation, setCitation] = useState(origCitations);

  const relationshipsOptions = useMemo(
    () =>
      project?.possibleRelationshipsTypes.map((rtype, index) => ({
        id: index,
        label: rtype,
      })) || [],
    [project]
  );

  const create = () => {
    if (requireCitations && !citation) {
      setError("You must include a citation for this relationship.");
    } else {
      onCreate({
        from: concepts[0].data.id,
        to: concepts[1].data.id,
        label: relationshipType.label,
        citation,
        citations: [citation]
      });
      onClose();
    }
  };

  const update = () => {
    if (requireCitations && !citation) {
      setError("You must include a citation for this relationship.");
    } else {
      onUpdate({
        id: relationship.data.id,
        from: concepts[0].data.id,
        to: concepts[1].data.id,
        label: relationshipType.label,
        citation,
        citations: [citation]
      });
      onClose();
    }
  };

  useEffect(() => {
    if (relationship) {
      const selectedRelationshipType = relationshipsOptions.find(
        (option) => option.label === relationship.data.label
      );
      setRelationshipType(selectedRelationshipType);
      setCitation(relationship.data.citation);
    }
  }, [relationship, relationshipsOptions]);

  return (
    <Draggable bounds="parent" handle="strong">
      <div
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
        className="absolute rounded-md w-[450px] shadow z-20 flex flex-col bg-white p-3"
        ref={dialogRef}
      >
        <div className="flex justify-between items-center mb-3 cursor-grab">
          <strong className="font-bold">
            {relationship ? "Update" : "Create"} Relationship
          </strong>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
            onClick={onClose}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="flex flex-col relative gap-2">
          {loading && <Spinner />}
          <div className="flex items-center gap-2">
            <input
              value={concepts[0].data.label}
              disabled
              className="w-[100px] truncate rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <ComboboxComponent
              classes="flex-1 mt-0"
              placeholder="Select relationship type"
              options={relationshipsOptions}
              selectedValue={relationshipType}
              onSelect={(rtype) => setRelationshipType(rtype)}
              allowNew={false}
              autofocus
            />
            <TextInput
              nativeProps={{ value: concepts?.[1]?.data?.label }}
              disabled
              classes="w-[100px] truncate rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          {requireCitations && (
            <TextInput
              placeholder="Citation"
              value={citation}
              onChange={setCitation}
            />
          )}
          {error && <ErrorBox error={error} classes="text-sm py-2" />}
          <Button
            disabled={!relationshipType}
            label={`${relationship ? "Update" : "Create"} Relationship`}
            onClick={relationship ? update: create}
          />
          {relationship && <Button classes={`text-red-600 border-red-600`}
              disabled={!relationshipType}
              label={`Delete Relationship`}
              onClick={onDelete}
          /> }
        </div>
      </div>
    </Draggable>
  );
};

export default CreateRelationshipDialog;
