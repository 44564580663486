import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.css";
import Account from "./components/screens/account/Account";
import Login from "./components/screens/authentication-flow/Login";
import Signup from "./components/screens/authentication-flow/Signup";
import ConceptMap from "./components/screens/concept-map/ConceptMap";
import StudentHome from "./components/screens/home/student/StudentHome";
import TeacherHome from "./components/screens/home/teacher/TeacherHome";
import NoPermission from "./components/screens/no-permission/NoPermission";
import Project from "./components/screens/project/Project";
import Student from "./components/screens/student/Student";
import { UserAuthContextProvider } from "./context/userAuthContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import RoleProtectedRoute from "./routes/RoleProtectedRoute";
import { ROLES } from "./utils/entities";
import EmailLinkSentSuccess from "./components/screens/authentication-flow/EmailLinkSentSuccess";
import ManageUsers from "./components/screens/super-user/ManageUser";
import Gradings from "./components/screens/gradings/Gradings";
import { PusherProvider } from "./context/PusherContext";

function App() {
  return (
    <RecoilRoot>
      <PusherProvider>
        <UserAuthContextProvider>
          <Routes>
            {/* Authentication */}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/no-permission" element={<NoPermission />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/email-link-sent" element={<EmailLinkSentSuccess />} />

            {/* Home */}
            <Route
              path="/teacher"
              element={
                <ProtectedRoute>
                  <RoleProtectedRoute role={ROLES.TEACHER}>
                    <TeacherHome />
                  </RoleProtectedRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/gradings"
              element={
                <ProtectedRoute>
                  <RoleProtectedRoute role={ROLES.TEACHER}>
                    <Gradings />
                  </RoleProtectedRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/manage-users"
              element={
                <ProtectedRoute>
                  <RoleProtectedRoute role={ROLES.TEACHER}>
                    <ManageUsers />
                  </RoleProtectedRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/student"
              element={
                <ProtectedRoute>
                  <RoleProtectedRoute role={ROLES.STUDENT}>
                    <StudentHome />
                  </RoleProtectedRoute>
                </ProtectedRoute>
              }
            />

            {/* Project */}
            <Route
              path="/project/:projectId/:studentId?"
              element={
                <ProtectedRoute>
                  <Project />
                </ProtectedRoute>
              }
            />
            {/* Student */}
            <Route
              path="/student/:studentId"
              element={
                <ProtectedRoute>
                  <Student />
                </ProtectedRoute>
              }
            />
            {/* Student */}
            <Route
              path="/concept-map/:mapId"
              element={
                <ProtectedRoute>
                  <ConceptMap />
                </ProtectedRoute>
              }
            />
            {/* Account */}
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
          </Routes>
        </UserAuthContextProvider>
      </PusherProvider>
    </RecoilRoot>
  );
}

export default App;
