import { atom } from "recoil";

export const projectState = atom({
  key: "projectState",
  default: undefined,
});

export const zoomState = atom({
    key: "zoomState",
    default: 1,
});
