import React, { useState } from "react";
import image from "../../../assets/logo.png";
import NavBar from "../home/nav-bar/NavBar";
import { ROLE_DISPLAY_NAME } from "../../../utils/entities";
import { useUserAuth } from "../../../context/userAuthContext";
import ConfirmationPopup from "../../shared/confirmation-popup/ConfirmationPopup";

const Account = () => {
  const { user, sendResetPasswordEmail } = useUserAuth();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const sendResetEmail = async () => {
    try {
      await sendResetPasswordEmail(user.email);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="min-h-full flex flex-col">
      <NavBar />
      <div
        className="h-full flex flex-col flex-grow items-center justify-center"
        style={{ paddingBottom: 250 }}
      >
        <div className="min-h-full flex flex-col items-center mb-5">
          <img
            src={image}
            className="mb-2"
            style={{ height: "100px", width: "100px" }}
            alt="profile"
          />
          <div className="flex flex-col text-center">
            <span className="font-bold text-3xl">{user.name}</span>
            <span className="text-xl py-1">{ROLE_DISPLAY_NAME[user.role]}</span>
            <span className="text-sm py-1">{user.email}</span>
          </div>
        </div>
        <button
          onClick={() => setShowConfirmPopup(true)}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        >
          Reset Password
        </button>
      </div>
      <ConfirmationPopup
        show={showConfirmPopup}
        onClose={() => setShowConfirmPopup(false)}
        onReject={() => setShowConfirmPopup(false)}
        onConfirm={() => {
          sendResetEmail().then();
          setShowConfirmPopup(false);
        }}
        content="Are you sure you want to reset your password?"
        confirmText="Send password reset email"
        width={350}
      />
    </div>
  );
};

export default Account;
