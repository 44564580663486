import Popup from "../Popup";
import {useState} from "react";

const LayoutPopup = ({
            show,
            run,
            onClose,
            onCancel,
            width = 320,
                           }) => {
    const [layout, setLayout] = useState();

    const onRun = () => {
        run?.(layout);
        onCancel();
    }

    const layouts = ["preset", "concentric", "grid", "random", "circle", "cose", "breadthfirst"];

    return (
        <Popup
            title={<span className={`text-md`}>Select Layout</span>}
            width={width}
            contentComponent={
                <div className="flex flex-col gap-2">
                    <div className="space-y-2">
                        { layouts.map((layout, index) => (
                            <div className="flex items-center space-x-2" key={index}>
                                <input type="radio" name="layout" id={layout} value={layout} className="form-radio" onChange={(e) => setLayout(e.target.value)} />
                                <label htmlFor={layout} className="cursor-pointer">{layout}</label>
                            </div>
                        ))}
                    </div>

                </div>
            }
            footerComponent={
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        onClick={onRun}
                        disabled={!layout}
                        type="submit"
                        className="flex justify-center mr-2 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Run
                    </button>
                    <button
                        type="button"
                        onClick={onCancel}
                        className="bg-white hover:bg-gray-100 text-gray-800 text-sm font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                    >
                        Cancel
                    </button>
                </div>
            }
            onCancel={onCancel}
            show={show}
            onClose={onClose}
        />
    );
};

export default LayoutPopup;
