import { downloadFile } from "../utils/helpers";
import axios from "./axios-interceptor";

export const getMapById = async (mapId) => {
  const { data } = await axios.get(`map/${mapId}`);
  return data;
};

export const getMapByProjectAndStudent = async (projectId, studentId) => {
  const { data } = await axios.get(
    `map/project/${projectId}/student/${studentId}`
  );
  return data;
};

export const exportMap = async (mapId, filename = "map", grading) => {
  const { data } = await axios.get(`map/${mapId}/export?grading=${grading ?? 'false'}`, {
    responseType: "arraybuffer",
  });

  downloadFile(data, `${filename}.csv`);
};

export const updateMapEntities = async (mapId, { concepts, relationships }) => {
  await axios.put(`map/${mapId}`, {
    concepts,
    relationships,
  });
};

export const sendMapEvents = async (projectId, mapId, events) => {
  await axios.put(`map/${mapId}/event?projectId=${projectId}`, {
    events,
  });
};
