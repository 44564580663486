import axios from "axios";

const WIKIPEDIA_BASE_URL = "https://en.wikipedia.org/w/api.php?origin=*";

function checkForDisambiguation(data) {
  const page = data.query.pages[Object.keys(data.query.pages)[0]];
  return page?.extract?.indexOf('may refer to:') > -1;
}

export const getWikipediaValuesByText = async (text) => {
  const {data} = await axios.get(
      `${WIKIPEDIA_BASE_URL}&action=opensearch&search=${text}&limit=10&namespace=0&format=json`
  );

  const values = data[1];
  const urls = data[3];

  const isAmbiguous = await Promise.all(urls.map(async (url, index) => {
    const {data} = await axios.get(
        `${WIKIPEDIA_BASE_URL}&action=query&titles=${values[index]}&prop=extracts|categories&exsentences=1&format=json&explaintext=true`
    );
    return checkForDisambiguation(data);
  }));

  const isRedirect = await Promise.all(urls.map(async (url, index) => {
    const {data} = await axios.get(
        `${WIKIPEDIA_BASE_URL}&action=query&titles=${values[index]}&prop=extracts&exsentences=1&format=json&explaintext=true&redirects=1`
    );
    return data.query.redirects;
  }));

  return values
      ?.filter((v, i) => !isAmbiguous[i])
      ?.filter((v, i) => !isRedirect[i])
      .map((value, index) => ({
        id: index,
        label: value,
        url: urls[index],
      }));
};

export const getValueInfoByTitle = async (title) => {
  const { data: firstParagraph } = await axios.get(
    `${WIKIPEDIA_BASE_URL}&action=query&titles=${title}&prop=extracts&exsentences=1&format=json&explaintext=true`
  );
  const { data: valueThumbImage } = await axios.get(
    `${WIKIPEDIA_BASE_URL}&action=query&prop=pageimages&format=json&pithumbsize=400&titles=${title}`
  );

  const pageId = Object.keys(firstParagraph.query.pages)[0];

  return {
    label: title,
    image: valueThumbImage.query.pages[pageId].thumbnail
      ? valueThumbImage.query.pages[pageId].thumbnail.source
      : null,
    description: firstParagraph.query.pages[pageId].extract,
    pageId: Number(pageId),
  };
};

export const getUrlByTitle = (title) => {
  return `https://en.wikipedia.org/wiki/${title}`;
};
