import { Link, useParams } from "react-router-dom";
import { ROLES } from "../../../../utils/entities";
import Select from "../../../shared/select/Select";
import { copyToClipboard } from "../../../../utils/helpers";
import { useRecoilState } from "recoil";
import { projectState } from "../../../../store/project";
import { useUserAuth } from "../../../../context/userAuthContext";
import {
  STUDENT_VIEW_MODE,
  STUDENT_VIEW_MODE_DISPLAY_NAME,
} from "../../../../utils/student-view-mode";
import ProjectHelp from "../help/Help";
import ProjectSettings from "../teacher-project/project-settings/ProjectSettings";
import ProjectLogs from "../teacher-project/project-logs/ProjectLogs";
import MergeCost from "../teacher-project/merge-cost/MergeCost";
import { useEffect, useState } from "react";
import ProjectSnapshot from "../snapshot/Snapshot";
import SnapshotSelector from "../snapshot/SnapshotSelector";
import {
  downloadSnapshotGradingSheet,
  downloadAllStudentsRelationships,
} from "../../../../api/project";
import {
  IoMdSettings,
  IoIosPaper,
  IoIosHelpBuoy,
  IoMdDownload,
} from "react-icons/io";
import { IoStatsChart, IoCamera } from "react-icons/io5";
import { Tooltip } from "flowbite-react";
import { IoMdPhotos } from "react-icons/io";
import { HiOutlineTable } from "react-icons/hi";
import Pusher from "pusher-js";
import DownloadProgress from "../snapshot/DownloadProgress";
import { usePusher } from "../../../../context/PusherContext";

const ProjectHeader = () => {
  const { user } = useUserAuth();
  const [project] = useRecoilState(projectState);
  const { studentId, projectId } = useParams();
  const isStudentMode = user?.role === ROLES.STUDENT || studentId;
  const [helpVisible, setHelpVisible] = useState(false);
  const [snapshotVisible, setSnapshotVisible] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);
  const [downloadAllVisible, setDownloadAllVisible] = useState(false);
  const [progressText, setProgressText] = useState("");
  const [progressDownloadAllText, setProgressDownloadAllText] = useState("");
  const [snapshotSelectorVisibleAll, setSnapshotSelectorVisibleAll] =
    useState(false);
  const [snapshotSelectorVisible, setSnapshotSelectorVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [mergeCostVisible, setMergeCostVisible] = useState(false);
  const [studentViewMode, setStudentViewMode] = useState(
    STUDENT_VIEW_MODE.STUDENT,
  );
  const pusher = usePusher();

  const studentViewModeOptions = [
    {
      value: STUDENT_VIEW_MODE.STUDENT,
      label: STUDENT_VIEW_MODE_DISPLAY_NAME[STUDENT_VIEW_MODE.STUDENT],
    },
    {
      value: STUDENT_VIEW_MODE.PROJECT,
      label: STUDENT_VIEW_MODE_DISPLAY_NAME[STUDENT_VIEW_MODE.PROJECT],
    },
  ];

  const snapshotSelected = async (snapshot) => {
    setSnapshotSelectorVisible(false);
    setProgressVisible(true);
    await downloadGradingSheet(snapshot);
  };

  const snapshotSelectedForDownloadAll = async (snapshot) => {
    setSnapshotSelectorVisibleAll(false);
    setDownloadAllVisible(true);
    await downloadAllStudentsRelationships(projectId, snapshot.id);
  };

  const downloadGradingSheet = async (snapshot) => {
    const filename = `${project.name} - ${snapshot.name}`;
    await downloadSnapshotGradingSheet(projectId, snapshot.id, filename);
  };

  useEffect(() => {
    const channel = pusher.subscribe("grading");

    channel.bind("download", function (data) {
      setProgressText(data.message);
    });

    channel.bind("download-all", function (data) {
      setProgressDownloadAllText(data.message);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  return (
    <header className="bg-white gap-[10px] flex-wrap shadow z-20 flex justify-between items-center pl-6 pr-3 py-3">
      <div className="flex items-center flex-wrap text-gray-900">
        <span className="font-bold text-3xl mr-2 underline">
          <Link to={`/project/${projectId}`}>{project && project.name}</Link>
        </span>
        {isStudentMode && (
          <span className="font-bold text-3xl mr-4"> - (Map)</span>
        )}
      </div>
      {progressVisible && (
        <DownloadProgress
          text={progressText}
          setVisible={setProgressVisible}
          visible={progressVisible}
        />
      )}
      <SnapshotSelector
        onSelected={snapshotSelected}
        project={project}
        visible={snapshotSelectorVisible}
        setVisible={setSnapshotSelectorVisible}
      />
      {downloadAllVisible && (
        <DownloadProgress
          text={progressDownloadAllText}
          setVisible={setDownloadAllVisible}
          visible={downloadAllVisible}
        />
      )}
      <SnapshotSelector
        onSelected={snapshotSelectedForDownloadAll}
        project={project}
        visible={snapshotSelectorVisibleAll}
        setVisible={setSnapshotSelectorVisibleAll}
      />
      <ProjectSnapshot
        project={project}
        visible={snapshotVisible}
        setVisible={setSnapshotVisible}
      />
      <ProjectHelp helpVisible={helpVisible} setHelpVisible={setHelpVisible} />
      <ProjectSettings
        project={project}
        visible={settingsVisible}
        setVisibility={(value) => setSettingsVisible(value)}
      />
      <ProjectLogs
        project={project}
        visible={logsVisible}
        setVisibility={(value) => setLogsVisible(value)}
      />
      <MergeCost
        projectId={project.id}
        visible={mergeCostVisible}
        setVisibility={(value) => setMergeCostVisible(value)}
      />
      {project && (
        <div className="flex items-center gap-2 flex-wrap">
          {user?.role === ROLES.STUDENT && (
            <Select
              classes="w-60"
              options={studentViewModeOptions}
              selected={studentViewModeOptions.find(
                (option) => option.value === studentViewMode,
              )}
              setSelected={setStudentViewMode}
            />
          )}

          <div className="flex items-center">
            {user?.role === ROLES.TEACHER && (
              <Tooltip
                content="Download all data for all students"
                placement="bottom"
              >
                <button
                  onClick={() => setSnapshotSelectorVisibleAll((v) => !v)}
                  className="flex items-center flex-1
                             rounded border px-3 py-2 border-gray-300 text-gray-900
                             placeholder-gray-500 focus:z-10 focus:border-indigo-500
                             focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <IoMdDownload />
                </button>
              </Tooltip>
            )}
            {user?.role === ROLES.TEACHER && (
              <Tooltip
                content="Download Snaphot (for scoring triples)"
                placement="bottom"
              >
                <button
                  onClick={() => setSnapshotSelectorVisible((v) => !v)}
                  className="flex items-center flex-1
                             rounded border px-3 py-2 border-gray-300 text-gray-900
                             placeholder-gray-500 focus:z-10 focus:border-indigo-500
                             focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <IoMdPhotos />
                </button>
              </Tooltip>
            )}

            {user?.role === ROLES.TEACHER && (
              <Tooltip content="Take Snapshot" placement="bottom">
                <button
                  onClick={() => setSnapshotVisible((logs) => !logs)}
                  className="flex items-center flex-1
                                       rounded border px-3 py-2 border-gray-300 text-gray-900
                                       placeholder-gray-500 focus:z-10 focus:border-indigo-500
                                       focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <IoCamera />
                </button>
              </Tooltip>
            )}
            {user?.role === ROLES.TEACHER && (
              <Tooltip
                content="View All Triples (And Upload Newly Scored Triples)"
                placement="bottom"
              >
                <button
                  onClick={() =>
                    window.open(`/gradings?id=${project.gradingId}`, "_blank")
                  }
                  className="flex items-center flex-1
                                       rounded border px-3 py-2 border-gray-300 text-gray-900
                                       placeholder-gray-500 focus:z-10 focus:border-indigo-500
                                       focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <HiOutlineTable />
                </button>
              </Tooltip>
            )}
            <Tooltip content="View Most Popular Triples" placement="bottom">
              <button
                onClick={() => {
                  setMergeCostVisible(true);
                }}
                className="flex items-center flex-1
                                       rounded border px-3 py-2 border-gray-300 text-gray-900
                                       placeholder-gray-500 focus:z-10 focus:border-indigo-500
                                       focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                type="button"
              >
                <IoStatsChart />
              </button>
            </Tooltip>
            {user?.role === ROLES.TEACHER && (
              <Tooltip content="Logs" placement="bottom">
                <button
                  onClick={() => setLogsVisible((logs) => !logs)}
                  className="flex items-center flex-1
                             rounded border px-3 py-2 border-gray-300 text-gray-900
                             placeholder-gray-500 focus:z-10 focus:border-indigo-500
                             focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <IoIosPaper />
                </button>
              </Tooltip>
            )}
            {user?.role === ROLES.TEACHER && (
              <Tooltip content="Settings" placement="bottom">
                <button
                  onClick={() => setSettingsVisible((help) => !settingsVisible)}
                  className="flex items-center flex-1
                    rounded border px-3 py-2 border-gray-300 text-gray-900
                     placeholder-gray-500 focus:z-10 focus:border-indigo-500
                     focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2"
                  type="button"
                >
                  <IoMdSettings />
                </button>
              </Tooltip>
            )}
            <Tooltip content="Instructions" placement="bottom">
              <button
                onClick={() => setHelpVisible((help) => !help)}
                className="flex items-center flex-1
                    rounded border px-3 py-2 border-gray-300 text-gray-900
                     placeholder-gray-500 focus:z-10 focus:border-indigo-500
                     focus:outline-none focus:ring-indigo-500 sm:text-sm"
                type="button"
              >
                <IoIosHelpBuoy />
              </button>
            </Tooltip>
          </div>
          <div className="flex items-center">
            <button
              id="dropdown-button"
              data-dropdown-toggle="dropdown"
              className="flex items-center flex-1 rounded-l-md border px-3 py-2 border-gray-300 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              type="button"
              onClick={() => copyToClipboard(project.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                />
              </svg>
              Project ID
            </button>
            <input
              id="generatedId"
              name="generatedId"
              value={project.id}
              disabled
              className="flex-1 min-w-[320px] block w-full appearance-none rounded-r-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default ProjectHeader;
