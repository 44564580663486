import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase-config";

export const FIREBASE_STORAGE_FOLDERS = {
  DEFAULT_MAPS: "default-maps",
  POSSIBLE_RELATIONSHIPS: "possible_relationships",
  POSSIBLE_CONCEPTS: "possible-concepts",
  COURSE_GRADINGS: "course-gradings",
};

export const uploadFileToFirebase = async (file, folder, name) => {
  const fileRef = ref(storage, `${folder}/${name}`);

  try {
    await uploadBytes(fileRef, file);
    const fullPath = await getDownloadURL(fileRef);
    return fullPath;
  } catch (e) {
    return null;
  }
};
