import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../utils/entities";
import {useUserAuth} from "../../../context/userAuthContext";

const NoPermission = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth()

  const goHome = () => {
    if (user?.role === ROLES.TEACHER) {
        navigate('/teacher')
    } else if (user?.role === ROLES.STUDENT) {
        navigate('/student')
    } else {
        navigate('/');
    }
  };

  return (
    <div className="w-full h-full px-20 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="w-full flex flex-col justify-center align-center">
        <h1 className="mb-1 text-gray-800 font-bold text-2xl">
          Oops! You don't have permissions to this page
        </h1>
        <p className="my-2 text-gray-800">
          Sorry about that! Please visit hompage to get where you need to go.
        </p>
        <button onClick={goHome} className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">
          Take me home
        </button>
      </div>
      <img
        alt="no-permission"
        src="https://i.ibb.co/ck1SGFJ/Group.png"
        style={{ height: 220, width: 350 }}
      />
    </div>
  );
};

export default NoPermission;
