import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { getUser } from "../../../api/user";
import Logo from "../../../assets/logo.png";
import { ROLES } from "../../../utils/entities";
import ForgetPasswordPopup from "./forget-password-popup/ForgetPasswordPopup";
import Spinner from "../../shared/spinner/Spinner";
import {auth} from "../../../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import {useUserAuth} from "../../../context/userAuthContext";
import Loading from "../../shared/spinner/Loading";


const Login = () => {
  // hooks
  const navigate = useNavigate();
  const { user, loading, setUser } = useUserAuth();

  // state
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const [showForgetPasswordPopup, setShowForgetPasswordPopup] = useState(false);

  // effects
  useEffect(() => {
    if (user) {
      navigate(user.role === ROLES.TEACHER ? "/teacher" : "/student")
    }
  }, [user]);

  if (loading) return null;

  // handlers
  const getFormError = () => {
    if (email === "") {
      return "Email is a required field";
    }
    if (password === "") {
      return "Password is a required field";
    }
    return "";
  };

  const getLoginError = (err) => {
    if (err.message.includes("invalid-email)")) {
      return "Invalid Email";
    }
    if (err.message.includes("wrong-password")) {
      return "Password is incorrect";
    }
    if (err.message.includes("user-not-found")) {
      return "That email isn’t in our database";
    }
    return "An error occurred";
  };

  const signIn = async (e) => {
    e.preventDefault();
    setSigningIn(true);
    setError("");

    const error = getFormError();
    if (error === "") {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        const loggedUser = await getUser();
        setUser({ ...loggedUser, email });
        if (loggedUser.role === ROLES.TEACHER){
          navigate("/teacher");
        } else {
          navigate("/student");
        }
      } catch (err) {
        const loginError = getLoginError(err);
        setError(loginError);
      } finally {
        setSigningIn(false);
      }
    } else {
      setError(error);
    }
  };

  const forgotPassword = () => {
    setShowForgetPasswordPopup(true);
  };

  return (
    <>
      {loading && <Spinner />}
      { !loading && <form className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" alt="UC Irvine" src={Logo} />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div className="mt-8 space-y-6">
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center"></div>
              <div
                onClick={forgotPassword}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                Forgot your password?
              </div>
            </div>
            <div>
              <button
                onClick={signIn}
                type="submit"
                disabled={signingIn}
                className={`group relative gap-2 items-center flex w-full justify-center rounded-md border
                border-transparent py-2 px-4 text-sm
                font-medium text-white hover:bg-indigo-700
                focus:outline-none focus:ring-2 focus:ring-indigo-500
                focus:ring-offset-2
                 ${signingIn ? 'bg-gray-400' : 'bg-indigo-600'}
                `}
              >
                { signingIn ? <Loading /> : null }
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                { signingIn ? 'Signing in' : 'Sign in' }
              </button>
            </div>
            {error && (
              <div
                className="bg-red-100 border border-red-400 text-center text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <div className="p-4 text-center">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </form>
      }
      {showForgetPasswordPopup && (
        <ForgetPasswordPopup
          show={showForgetPasswordPopup}
          onClose={() => setShowForgetPasswordPopup(false)}
        />
      )}
    </>
  );
};

export default Login;
