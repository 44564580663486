const getConceptById = (concepts, conceptId) => {
  return concepts.find(
    (concept) => concept.data("id").toString() === conceptId.toString()
  );
};

export const getConceptsToHideWhenCollapse = (concepts, conceptId) => {
  const conceptToCollapse = getConceptById(concepts, conceptId);

  const conceptsToHide = [];

  if (conceptToCollapse) {
    // find its direct neighbors
    const neighbors = conceptToCollapse.neighborhood("node{degree = 1}");

    neighbors.forEach((neighbor) => {
      const hasVisibleNeighbors = hasVisiblenNeighbors(neighbor, [
        conceptId.toString(),
      ]);
      if (!hasVisibleNeighbors) {
        conceptsToHide.push({
          id: neighbor.data("id").toString(),
          label: neighbor.data("label").toString(),
        });
      }
    });
  }
  return conceptsToHide;
};

export const getConceptsToShowWhenExpand = (concepts, conceptId) => {
  const conceptToExpand = getConceptById(concepts, conceptId);

  let conceptsToShow = [];

  if (conceptToExpand) {
    // find its direct neighbors
    const neighbors = conceptToExpand.neighborhood("node{degree = 1}");
    conceptsToShow = neighbors.map((neighbor) => ({
      id: neighbor.data("id"),
      label: neighbor.data("label"),
    }));
  }

  return conceptsToShow;
};

export const getConceptsToShowWhenExpandAll = (concepts) => {
  const conceptsToExpand = concepts.filter(
    (concept) => !concept.data("expanded")
  );

  let conceptsToShow = [];

  conceptsToExpand.forEach((collapsedConcept) => {
    const neighbors = collapsedConcept.neighborhood("node{degree = 1}");
    conceptsToShow = [
      ...conceptsToShow,
      ...neighbors.map((neighbor) => ({
        id: neighbor.data("id"),
        label: neighbor.data("label"),
      })),
    ];
    conceptsToShow.push({
      id: collapsedConcept.data("id"),
      label: collapsedConcept.data("label"),
    });
  });

  return conceptsToShow;
};

export const getConceptsToHideWhenHide = (cy, conceptId) => {
  const conceptToHide = getConceptById(cy.nodes(), conceptId);

  let conceptsIdsToHide = [];

  if (conceptToHide) {
    conceptsIdsToHide = getAllConnectedNodes(conceptToHide);
  }
  return conceptsIdsToHide;
};

const hasVisiblenNeighbors = (concept, skipConceptsIds = []) => {
  return concept
    .neighborhood("node{degree = 1}")
    .some(
      (neighbor) =>
        !skipConceptsIds.includes(neighbor.data("id").toString()) &&
        neighbor.data("visible")
    );
};

const getAllConnectedNodes = (startConcept, visited = new Set()) => {
  visited.add(startConcept.data("id"));
  const neighbors = startConcept.neighborhood("node");

  let newVisited = new Set(visited);

  neighbors.forEach((neighbor) => {
    if (!visited.has(neighbor.data("id"))) {
      newVisited = new Set(getAllConnectedNodes(neighbor, newVisited));
    }
  });

  return newVisited;
};
