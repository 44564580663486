import { useEffect, useState } from "react";
import { getCourses } from "../../../../../api/course";
import {
  createProject,
  getProjectGeneratedId,
} from "../../../../../api/project";
import {
  FIREBASE_STORAGE_FOLDERS,
  uploadFileToFirebase,
} from "../../../../../utils/firebase-storage";
import ComboboxComponent from "../../../../shared/combobox/Combobox";
import FileUploader from "../../../../shared/FileUploader";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";

const AddProjectPopup = ({ show, onClose, onAddProject }) => {
  const [loading, toggleLoading] = useState(false);
  const [generatedId, setGeneratedId] = useState("");
  const [name, setName] = useState("");
  const [emailSuffix, setEmailSuffix] = useState("");
  const [course, setCourse] = useState();
  const [courses, setCourses] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [isRequireCitations, setIsRequireCitations] = useState(false);
  const [defaultMap, setDefaultMap] = useState();
  const [error, setError] = useState("");

  const fetchCourses = async () => {
    const courses = await getCourses();
    const coursesOptions = courses.map((course, index) => ({
      id: index,
      label: course.name,
    }));
    setCourses(coursesOptions);
  };

  const addProject = async () => {
    toggleLoading(true);
    try {
      let downloadUrl;

      // If default map chosen
      if (defaultMap) {
        const mapStorageName = `${name}-${
          defaultMap.name.split(".")[0]
        }-${new Date().getTime()}.csv`;

        downloadUrl = await uploadFileToFirebase(
          defaultMap,
          FIREBASE_STORAGE_FOLDERS.DEFAULT_MAPS,
          mapStorageName
        );
      }

      const newProject = await createProject({
        uid: generatedId,
        name,
        editable: isEditable,
        requireCitations: isRequireCitations,
        defaultMapStorageUrl: downloadUrl,
        course: { name: course.label },
        emailSuffix,
      });
      onClose();
      onAddProject(newProject.id);
    } catch (err) {
      setError(err.message);
    } finally {
      toggleLoading(false);
    }
  };

  const getGeneratedId = async () => {
    const id = await getProjectGeneratedId();
    setGeneratedId(id);
  };

  useEffect(() => {
    getGeneratedId().then();
    fetchCourses().then();
  }, []);

  return (
    <Popup
      title="Add Project"
      width={600}
      contentComponent={
        <div className="flex flex-col relative">
          {loading && <Spinner text="Creating project..." />}
          <div className="flex items-center mb-3">
            <span className="mr-2">Project ID:</span>
            <input
              id="generatedId"
              name="generatedId"
              value={generatedId}
              disabled
              className="grow appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className={`mb-3 bg-orange-100 p-2 text-orange-700 rounded`}>
            Give this ID to students for them to join the course
          </div>
          <input
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            required
            className="relative block w-full appearance-none rounded-md border border-gray-300 mb-1 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            placeholder="Project Name"
          />
          <ComboboxComponent
            placeholder="Course"
            options={courses}
            selectedValue={course}
            onSelect={(selectedCourse, isNew) => {
              if (isNew) {
                const course = {
                  id:
                    courses.length > 0 ? courses[courses.length - 1].id + 1 : 1,
                  label: selectedCourse.name,
                };
                setCourses((oldCourse) => [...oldCourse, course]);
                setCourse(course);
              } else {
                setCourse(selectedCourse);
              }
            }}
          />
          <input
              id="emailSuffix"
              name="Email Suffix"
              onChange={(e) => setEmailSuffix(e.target.value)}
              required
              className="relative mt-2 block w-full appearance-none rounded-md border border-gray-300 mb-1 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Email Suffix (i.e @uci.edu, leave blank to allow all)"
          />
          <div className="flex items-center pt-2 mb-2 divide-x gap-2">
            <div className="flex items-center cursor-pointer">
              <input
                id="editable"
                onChange={() => setIsEditable(!isEditable)}
                type="checkbox"
                checked={isEditable}
                className="rounded checked ml-1 my-2"
              />
              <label className="pl-2 text-sm" htmlFor="editable">
                Editable
              </label>
            </div>
            <div className="flex items-center cursor-pointer pl-2">
              <input
                id="citations"
                onChange={() => setIsRequireCitations(!isRequireCitations)}
                type="checkbox"
                checked={isRequireCitations}
                className="rounded checked ml-1 my-2"
              />
              <label className="pl-2 text-sm" htmlFor="citations">
                Require citations
              </label>
            </div>
          </div>

          <FileUploader
            file={defaultMap}
            onFileUpload={setDefaultMap}
            acceptedExtenstion=".csv"
            description={
              <>
                <span className="font-bold">Upload default map </span>(click or
                drag and drop)
              </>
            }
          />
           <div className={`mt-4 text-red-500`}>
             { error }
          </div>
        </div>
      }
      onSubmit={addProject}
      onCancel={onClose}
      show={show}
      onClose={onClose}
      allowSubmit={!loading}
    />
  );
};

export default AddProjectPopup;
