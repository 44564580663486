const ErrorBox = ({ error, classes = "" }) => {
  return (
    <div
      className={`bg-red-100 border border-red-400 text-center text-red-700 px-4 py-3 rounded relative ${classes}`}
      role="alert"
    >
      <span className="block sm:inline">{error}</span>
    </div>
  );
};

export default ErrorBox;
