import axios from "axios";
import { auth } from "../firebase-config";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

axiosInstance.interceptors.request.use(async function (config) {
    const user = auth.currentUser;
    if (user) {
        const token = await auth.currentUser.getIdToken();
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    }
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
      const originalRequest = error.config;
      if ((error?.response?.status === 403 || error?.status === 403) && !originalRequest._retry) {
          originalRequest._retry = true;
          if (auth.currentUser) {
              const token = await auth.currentUser.getIdToken();
              axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              return axiosInstance(originalRequest);
          }
      }
      return Promise.reject(error?.response?.data ?? error);
  }
);

export default axiosInstance;
