import { AiOutlineClose } from "react-icons/ai";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import FileUploader from "../../shared/FileUploader";
import {
  FIREBASE_STORAGE_FOLDERS,
  uploadFileToFirebase,
} from "../../../utils/firebase-storage";
import { updateGrading } from "../../../api/gradings";
import { usePusher } from "../../../context/PusherContext";

const UploadGradingsCSV = ({ visible, setVisible, courseGrading, onDone }) => {
  const [text, setText] = useState("");
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState("");
  const pusher = usePusher();

  const uploadFile = async () => {
    if (!file) return;
    const fileName = `${courseGrading.name}-${new Date().getTime()}.csv`;

    setUploading(true);
    try {
      const downloadUrl = await uploadFileToFirebase(
        file,
        FIREBASE_STORAGE_FOLDERS.COURSE_GRADINGS,
        fileName,
      );

      await updateGrading(courseGrading.id, downloadUrl);
    } catch (e) {
      setError("Failed to upload file");
    } finally {
      setUploading(false);
    }
  };

  const close = () => {
    setText("");
    setVisible(false);
    setFile(null);
  };

  useEffect(() => {
    const channel = pusher.subscribe("grading");
    channel.bind("upload", function (data) {
      setText(data.message);

      if (data.done) {
        setUploading(false);
        setFile(null);
        if (onDone) onDone();
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className={`flex flex-1 items-center justify-between`}>
                    Upload Grading CSV
                    <span
                      className={`cursor-pointer`}
                      onClick={() => setVisible(false)}
                    >
                      <AiOutlineClose />
                    </span>
                  </div>
                </Dialog.Title>
                <div className="pt-2">
                  {!uploading && (
                    <FileUploader
                      file={file}
                      onFileUpload={setFile}
                      acceptedExtenstion=".csv"
                      description={
                        <>
                          <div>
                            <span className="font-bold">
                              Upload gradings CSV
                            </span>
                          </div>
                          <div>
                            <span>(click or drag and drop)</span>
                          </div>
                        </>
                      }
                    />
                  )}
                  <div className={`mt-4`}>{text}</div>
                  <div className={`mt-4 text-red-500`}>{error}</div>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={uploadFile}
                    disabled={uploading || !file}
                    className="disabled:opacity-50 group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {uploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadGradingsCSV;
