import { useState } from "react";
import {
  FIREBASE_STORAGE_FOLDERS,
  uploadFileToFirebase,
} from "../../../../../utils/firebase-storage";
import {
  deleteProjectPossibleRelationships,
  setProjectPossibleRelationshipsTypes, setProjectPossibleRelationshipsTypesColors,
} from "../../../../../api/project";
import FileUploader from "../../../../shared/FileUploader";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";
import Chip from "../../../../shared/chip/Chip";
import {HuePicker} from "react-color";
import { Modal, Button, Tabs } from 'flowbite-react';


const SetRelationshipsPopup = ({ show, onClose, project, onSet }) => {
  const [relationshipsCsv, setRelationshipsCsv] = useState();
  const [loading, toggleLoading] = useState(false);
  const [savingColors, setSavingColors] = useState(false);
  const [relationshipsToDelete, setRelationshipsToDelete] = useState([]);
  const [colors, setColors] = useState(project.possibleRelationshipsTypesColors ?? {});

  const relationships = project.possibleRelationshipsTypes || [];

  const visibleRelationships = relationships.filter(
      (relationship) => !relationshipsToDelete.includes(relationship)
  );

  const prepareToDelete = (relationship) => {
    setRelationshipsToDelete((toDelete) => [...toDelete, relationship]);
  };

  const setRelationships = async () => {
    toggleLoading(true);

    try {
      let downloadUrl;

      if (relationshipsCsv) {
        const relationshipStorageName = `${project.name}-${
            relationshipsCsv.name.split(".")[0]
        }-${new Date().getTime()}.csv`;

        downloadUrl = await uploadFileToFirebase(
            relationshipsCsv,
            FIREBASE_STORAGE_FOLDERS.POSSIBLE_RELATIONSHIPS,
            relationshipStorageName
        );

        await setProjectPossibleRelationshipsTypes({
          id: project.id,
          relationshipsCsvStorageUrl: downloadUrl,
        });

      } else if (relationshipsToDelete.length > 0) {
        await deleteProjectPossibleRelationships({
          id: project.id,
          relationshipsToDelete,
        });

      }

      await onSet();
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  const saveColors = async () => {
    setSavingColors(true);

    try {
      await setProjectPossibleRelationshipsTypesColors({
        id: project.id,
        colors,
      });

    } catch (err) {
      console.log(err);
    } finally {
      setSavingColors(false);
    }
  };

  return (
      <Modal dismissible show={show} onClose={onClose}>
        <Modal.Header>Terms of Service</Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            <Tabs aria-label="Default tabs" style="default">
              <Tabs.Item active title="Relationships">
                <div className="flex flex-col relative">
                  {loading && <Spinner text="Setting Relationships..."/>}
                  {project.possibleRelationshipsTypes &&
                      visibleRelationships.length > 0 && (
                          <div className="flex flex-col mb-3">
                <span className="text-sm font-bold mb-2">
                  Current possible relationship types
                </span>
                            <div className="flex">
                              {visibleRelationships.map((relationshipType) => (
                                  <Chip
                                      onRemove={() => prepareToDelete(relationshipType)}
                                      key={relationshipType}
                                      text={relationshipType}
                                  />
                              ))}
                            </div>
                          </div>
                      )}
                  <FileUploader
                      file={relationshipsCsv}
                      onFileUpload={setRelationshipsCsv}
                      acceptedExtenstion=".csv"
                      description={
                        <>
                <span className="font-bold">
                  Upload possible relationships types
                </span>
                          (click or drag and drop)
                        </>
                      }
                  />
                </div>
              </Tabs.Item>
              <Tabs.Item title="Colors">
                <div className="flex flex-col relative min-h-[320px] gap-2">
                  {visibleRelationships.map((relationshipType) => (
                      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                  <span>
                    <div style={{width: 25, height: 18, backgroundColor: colors[relationshipType] ?? '#8c8c8c'}}/>
                  </span>
                        <span>
                      <HuePicker style={{width: 100}} color={colors[relationshipType]} onChange={(color) => {
                        setColors((prev) => ({
                          ...prev,
                          [relationshipType]: color.hex,
                        }));
                      }}/>
                    </span>
                        <span>
                    {relationshipType}
                    </span>
                      </div>
                  ))}
                  <br/>
                </div>
              </Tabs.Item>
            </Tabs>


          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={(loading || savingColors) ? 'disabled': undefined} onClick={async () => {
            await saveColors();
            await setRelationships();
          }}>{ loading || savingColors ? 'Saving...' : 'Save' }</Button>
        </Modal.Footer>
      </Modal>
  );
};

export default SetRelationshipsPopup;
