import {
  FIREBASE_STORAGE_FOLDERS,
  uploadFileToFirebase,
} from "../../../../../utils/firebase-storage";
import { replaceProjectDefaultMap } from "../../../../../api/project";
import FileUploader from "../../../../shared/FileUploader";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";
import { useState } from "react";

const ReplaceDefaultMapPopup = ({ show, onClose, project, onReplaceMap }) => {
  const [defaultMap, setDefaultMap] = useState();
  const [loading, toggleLoading] = useState(false);

  const replaceMap = async () => {
    toggleLoading(true);
    try {
      let downloadUrl;

      if (defaultMap) {
        const mapStorageName = `${project.name}-${
          defaultMap.name.split(".")[0]
        }-${new Date().getTime()}.csv`;

        downloadUrl = await uploadFileToFirebase(
          defaultMap,
          FIREBASE_STORAGE_FOLDERS.DEFAULT_MAPS,
          mapStorageName
        );

        await replaceProjectDefaultMap({
          id: project.id,
          defaultMapStorageUrl: downloadUrl,
        });
        onReplaceMap();
      }

      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <Popup
      title="Add or Replace Map"
      width={600}
      contentComponent={
        <div className="flex flex-col relative">
          {loading && <Spinner />}
          <FileUploader
            file={defaultMap}
            onFileUpload={setDefaultMap}
            acceptedExtenstion=".csv"
            description={
              <>
                <span className="font-bold">Upload map </span>(click or
                drag and drop)
              </>
            }
          />
        </div>
      }
      needConfirmation
      confirmationContent={
        <span>
          <p className="font-bold text-center mb-2">Are you sure?</p>
          This will replace the current map with the one you are uploading.
        </span>
      }
      allowSubmit={!!defaultMap}
      onSubmit={replaceMap}
      onCancel={onClose}
      show={show}
      onClose={onClose}
    />
  );
};

export default ReplaceDefaultMapPopup;
