const CytoInfoPanel = ({ nodesCount, relationshipsCount, showMetrics, onClick }) => {
  return (
    <div className="absolute z-10 bottom-3 right-3 flex items-center gap-2">
      {showMetrics && onClick && (
        <button
          onClick={onClick}
          className="bg-white flex items-center justify-center hover:bg-gray-100 text-sm text-gray-800 font-semibold p-2 border border-[#e5e7eb] rounded-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
            />
          </svg>
        </button>
      )}
      <div className="flex items-center justify-center bg-[#dfdfdf] py-2 px-4 text-sm border border-[#e5e7eb] rounded">
        <span>
          {nodesCount} nodes | {relationshipsCount} relationship{relationshipsCount !== 1 && "s"}
        </span>
      </div>
    </div>
  );
};

export default CytoInfoPanel;
