import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProjectsWithMaps } from "../../../api/project";
import { getUserByFirebaseid } from "../../../api/user";
import NavBar from "../home/nav-bar/NavBar";

const Student = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();

  const [student, setStudent] = useState();
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    const studentProjects = await getUserProjectsWithMaps(studentId);
    setProjects(studentProjects);
  };

  const fetchStudent = async () => {
    const student = await getUserByFirebaseid(studentId);
    setStudent(student);
  };

  const goToMap = (project) => {
    if (student && project) {
      navigate(`/project/${project.id}/${student.firebaseUid}`);
    }
  };

  useEffect(() => {
    fetchStudent();
    fetchProjects();
  }, []);

  return (
    <div className="min-h-full min-w-full flex flex-col">
      <NavBar />
      <div className="flex flex-col flex-1">
        <header className="bg-white shadow z-10">
          <div className="flex flex-col justify-center flex-wrap px-6 py-4 ext-gray-900">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {student ? student.name : "Student"}
            </h1>
          </div>
        </header>
        <div className="px-6 py-4">
          <span className="font-bold text-xl">Concept maps</span>
        </div>
        {projects.map((project) => (
          <div
            key={project.id}
            onClick={() => goToMap(project)}
            className="py-2 px-2 border-b-2 hover:bg-[#e5e5e5] cursor-pointer pl-6"
          >
            <span className="font-bold">{project.name} </span>
            <span className="text-[gray]">| {project.course.name} </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Student;
