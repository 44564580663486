import "./project-list.css";
import {Button} from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import { ROLES } from "../../../../utils/entities";
import Spinner from "../../../shared/spinner/Spinner";
import {useUserAuth} from "../../../../context/userAuthContext";
import AddProjectPopup from "./add-project-popup/AddProjectPopup";
import JoinProjectPopup from "./join-project-popup/JoinProjectPopup";
import { getProjects, removeProjectAdmin } from "../../../../api/project";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import EditProjectAdminsPopup from "./edit-project-admins-popup/EditProjectAdminsPopup";

const ProjectList = () => {
  const {user} = useUserAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [isAddProjectPopupOpen, setIsAddProjectPopupOpen] = useState(false);
  const [isJoinProjectPopupOpen, setIsJoinProjectPopupOpen] = useState(false);
  const [isEditProjectAdminsPopupOpen, setIsEditProjectAdminsPopupOpen] = useState(false);

  useEffect(() => {
    fetchProjects().then();
  }, []);

  const projectsTree = () => {
    const projectsByCourses = {};

    projects.forEach((project) => {
      projectsByCourses[project.course_name] = [
        ...(projectsByCourses[project.course_name] || []),
        project,
      ];
    });

    return projectsByCourses;
  };

  const onNewProject = async (newProjectId) => {
    navigate(`/project/${newProjectId}`);
  };

  const fetchProjects = async () => {
    setLoading(true);
    const projects = await getProjects();
    setProjects(projects);
    setLoading(false);
  };

  const goToProject = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const removeAdminFromProject = async (projectId, adminId) => {
    try {
      await removeProjectAdmin(projectId, adminId);
      await fetchProjects();
    } catch (e) {
      console.log(e);
    }
  }

  const prettifyRelationshipType = ([rel] = "") => {
    if (rel === 'ADMIN_OF')
      return "Admin";
    else if (rel === 'OWNER_OF')
      return "Owner";
    else
      return "";
  }

  return (
      <div className="flex flex-col w-full">
        {loading ? (
            <Spinner text="Loading projects..."/>
        ) : (
            <>
              <div className="px-6 py-4 flex justify-between items-center">
                <span className=" text-xl">Projects</span>
                <div className={`flex items-align gap-4`}>
                  <Button color="blue"
                          onClick={() => {
                            if (user?.role === ROLES.TEACHER) {
                              setIsAddProjectPopupOpen(true);
                            } else if (user?.role === ROLES.STUDENT) {
                              setIsJoinProjectPopupOpen(true);
                            }
                          }}>
                    {user?.role === ROLES.TEACHER ? "Add" : "Join"} Project
                  </Button>
                </div>
              </div>
              {Object.entries(projectsTree()).map(([course, projects]) => (
                  <div className="py-4" key={course}>
                    <div className={`pl-6`}>
                      <table className={`text-sm text-gray-600`}>
                        <thead>
                        <tr>
                          <th colSpan={7} className={`text-left border py-2 px-4 bg-slate-100`}>
                            <span className={`text-lg`}>
                            {course}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className={`text-left border py-1 px-4 bg-slate-100`}>Project Name</th>
                          <th className={`text-left border py-1 px-4 bg-slate-100`}>Project ID</th>
                          <th className={`text-left border py-1 px-4 bg-slate-100`}>No. Students</th>
                          <th className={`text-left border py-1 px-4 bg-slate-100`}>Creation Date</th>
                          <th className={`text-left border py-1 px-4 bg-slate-100`}>Created By</th>
                          {user?.role === ROLES.TEACHER && <th className={`text-left border py-1 px-4 bg-slate-100`}>My Permissions</th> }
                          {user?.role === ROLES.TEACHER && <th className={`text-left border py-1 px-4 bg-slate-100`}>Admins</th> }
                        </tr>
                        </thead>
                        <tbody>
                        {projects.map((project) => (
                            <tr key={project.id} className="py-1 hover:bg-gray-100">
                              <td className={`border py-2 px-4`}>
                                <a className={`cursor-pointer underline text-blue-900`}
                                   onClick={() => goToProject(project.id)}>
                                  {project.name}
                                </a>
                              </td>
                              <td className={`border py-1 px-4`}>{project.id}</td>
                              <td className={`border py-1 px-4`}>{project.studentCount === 0 ? '-' : project.studentCount}</td>
                              <td className={`border py-1 px-4`}>{project.createdAt ? (new Date(project.createdAt)).toDateString() : "-"}</td>
                              <td className={`border py-1 px-4`}>{project.createdByName ?? "-"}</td>
                              { user?.role === ROLES.TEACHER && <td className={`border py-1 px-4`}>{prettifyRelationshipType(project.relationshipTypes)}</td> }
                              {user?.role === ROLES.TEACHER && <td className={`border py-1 px-4`}>
                              <div className="flex gap-3 items-center">
                                <PlusCircleIcon onClick={() => {
                                  setEditingProjectId(project.id);
                                  setIsEditProjectAdminsPopupOpen(true);
                                }} className="h-5 w-5 cursor-pointer"/>
                                <div className="flex gap-2 items-center justify-center">
                                  {project?.admins?.map(admin => {
                                    if (!admin.id) return null;
                                        return <div className="flex gap-2 px-2 py-1 rounded-md bg-gray-700 text-white">
                                          <span>{admin.name}</span>
                                          <MinusCircleIcon onClick={() => {
                                            removeAdminFromProject(project.id, admin.id);
                                          }} className="h-5 w-5 cursor-pointer"/>
                                        </div>
                                      }
                                  )}
                                </div>
                              </div>
                              </td>
                              }
                            </tr>))}
                        </tbody>
                      </table>
                      <br/>
                      <br/>
                    </div>
                  </div>
              ))}

              {isAddProjectPopupOpen && user?.role === ROLES.TEACHER && (
                  <AddProjectPopup
                      show={isAddProjectPopupOpen}
                      onClose={() => setIsAddProjectPopupOpen(false)}
                      onAddProject={onNewProject}
                  />
              )}

              {isEditProjectAdminsPopupOpen && user?.role === ROLES.TEACHER && (
                  <EditProjectAdminsPopup
                      show={isEditProjectAdminsPopupOpen}
                      projectId={editingProjectId}
                      onClose={() => {
                        setIsEditProjectAdminsPopupOpen(false);
                        fetchProjects();
                      }
                  }
                  />
              )}

              {isJoinProjectPopupOpen && user?.role === ROLES.STUDENT && (
                  <JoinProjectPopup
                      show={isJoinProjectPopupOpen}
                      onClose={() => setIsJoinProjectPopupOpen(false)}
                      onAddProject={onNewProject}
                  />
              )}

            </>
        )}
      </div>
  );
}

export default ProjectList;
