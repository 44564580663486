export const EventType = {
  Update: "Update",
  Create: "Create",
  Delete: "Delete",
};

export const EventEntity = {
  Concept: "Concept",
  Relationship: "Relationship",
};
