export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const isStrongPassword = (password) => {
  return true;
  // const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
  // return regex.test(password);
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const downloadFile = (blobData, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
};


export function compareObjects(obj1, obj2) {
  // Check all keys in obj1
  for (let key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      // Check if key exists in obj2 and if the value is the same
      if (!(key in obj2) || obj1[key] !== obj2[key]) {
        return true; // Difference found
      }
    }
  }

  // Check for any extra keys in obj2
  for (let key in obj2) {
    if (obj2.hasOwnProperty(key) && !(key in obj1)) {
      return true; // Extra key found in obj2
    }
  }

  // No differences found
  return false;
}

