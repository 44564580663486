import React from "react";
import './slider-thumb.css';
import {useRecoilValue} from "recoil";
import {zoomState} from "../../../store/project";

const Slider = ({ onChange, style = {}, min = 0.05, max= 4 }) => {
  const zoom = useRecoilValue(zoomState);
  return (
      <input
          type="range"
          className="range-slider transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
          onChange={onChange}
          style={style}
          min={min}
          max={max}
          step={0.01}
          value={zoom}
      />
  );
};

export default Slider;
