import axios from "./axios-interceptor";

export const getGradings = async () => {
  const { data } = await axios.get(`gradings`);
  return data;
};

export const createGrading = async (grading) => {
  const { data } = await axios.post(`gradings`, grading);
  return data;
};

export const getGradingDetails = async (gradingId) => {
  const { data } = await axios.get(`gradings/${gradingId}/details`);
  return data;
};

export const updateGrading = async (gradingId, csvUrl) => {
  const { data } = await axios.put(`gradings/${gradingId}`, {
    csvUrl,
  });
  return data;
};

export const updateGradingScore = async (gradingId, triple) => {
  const { data } = await axios.put(`gradings/${gradingId}/score`, {
    triple,
  });
  return data;
};
