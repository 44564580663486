import { useState } from "react";
import {
  deleteProjectPossibleConcepts,
  setProjectPossibleConcepts,
} from "../../../../../api/project";
import {
  FIREBASE_STORAGE_FOLDERS,
  uploadFileToFirebase,
} from "../../../../../utils/firebase-storage";
import Chip from "../../../../shared/chip/Chip";
import FileUploader from "../../../../shared/FileUploader";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";

const SetConceptsPopup = ({ show, onClose, project, onSet }) => {
  const [conceptsCsv, setConceptsCsv] = useState();
  const [loading, toggleLoading] = useState(false);
  const [conceptsToDelete, setConceptsToDelete] = useState([]);

  const concepts = project.possibleConcepts || [];

  const visibleConcepts = concepts.filter(
    (concept) => !conceptsToDelete.includes(concept)
  );

  const prepareToDelete = (concept) => {
    setConceptsToDelete((toDelete) => [...toDelete, concept]);
  };

  const setConcepts = async () => {
    toggleLoading(true);

    try {
      let downloadUrl;

      if (conceptsCsv) {
        const conceptsStorageName = `${project.name}-${
          conceptsCsv.name.split(".")[0]
        }-${new Date().getTime()}.csv`;

        downloadUrl = await uploadFileToFirebase(
          conceptsCsv,
          FIREBASE_STORAGE_FOLDERS.POSSIBLE_CONCEPTS,
          conceptsStorageName
        );

        await setProjectPossibleConcepts({
          id: project.id,
          conceptsCsvStorageUrl: downloadUrl,
        });

        onSet();
      } else if (conceptsToDelete.length > 0) {
        await deleteProjectPossibleConcepts({
          id: project.id,
          conceptsToDelete,
        });
        onSet();
      }

      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <Popup
      title="Set Possible Concepts"
      width={600}
      contentComponent={
        <div className="flex flex-col relative">
          {loading && <Spinner text="Setting Concepts..." />}
          {project.possibleConcepts && project.possibleConcepts.length > 0 && (
            <div className="flex flex-col mb-3">
              <span className="text-sm font-bold mb-2">
                Current possible concepts
              </span>
              <div className="flex">
                {visibleConcepts.map((conceptName) => (
                  <Chip
                    key={conceptName}
                    text={conceptName}
                    onRemove={() => prepareToDelete(conceptName)}
                  />
                ))}
              </div>
            </div>
          )}
          <FileUploader
            file={conceptsCsv}
            onFileUpload={setConceptsCsv}
            acceptedExtenstion=".csv"
            description={
              <>
                <span className="font-bold">Upload possible concepts</span>
                (click or drag and drop)
              </>
            }
          />
        </div>
      }
      allowSubmit={conceptsCsv || conceptsToDelete.length > 0}
      onSubmit={setConcepts}
      onCancel={onClose}
      show={show}
      onClose={onClose}
    />
  );
};

export default SetConceptsPopup;
