import axios from "./axios-interceptor";

export const createUser = async (createUserInput) => {
    const { data } = await axios.post(`user`, {
        firebaseUid: createUserInput.firebaseUid,
        name: createUserInput.name,
        role: createUserInput.role,
    });
    return data;
}

export const getUser = async () => {
    const { data } = await axios.get(`user`);
    return data;
}

export const getUserByFirebaseid = async (firebaseUid) => {
    const { data } = await axios.get(`user/firebase/${firebaseUid}`);
    return data;
}

export const findUserByEmail = async (email) => {
    const { data } = await axios.get(`user/email/${email}`);
    return data;
}

export const addAdminToProject = async (userId, projectId) => {
    const { data } = await axios.post(`user/${userId}/project/${projectId}`);
    return data;
}

export const getAllUsers = async () => {
    const { data } = await axios.get(`user/all`);
    return data;
}


export const updateFirebaseUser = async (firebaseUid, payload) => {
    const { data } = await axios.put(`user/firebase/${firebaseUid}`, payload);
    return data;
}

export const updateUser = async (userId, role) => {
    const { data } = await axios.patch(`user/${userId}/role`, role);
    return data;
}
