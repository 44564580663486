import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AiOutlineClose} from "react-icons/ai";
import {getProjectLogs} from "../../../../../api/project";
import dayjs from "dayjs";
import {useUserAuth} from "../../../../../context/userAuthContext";
import {ROLES} from "../../../../../utils/entities";

const ProjectLogs = ({ project, visible, setVisibility }) => {
    const { user } = useUserAuth();
    const [logs, setLogs] = useState([]);
    const gridRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const onGridReady = params => {
        gridRef.current = params.api;
    };

    const exportToCsv = () => {
        gridRef.current.exportDataAsCsv();
    };

    const [columnDefs] = useState([
        {
            field: "timestamp", sortable: true, filter: true, width: 240,
            cellRenderer: (param) => {
                return dayjs(param.value).format('MMMM D, YYYY h:mm A')
            }
        },
        {field: "message", sortable: true, filter: true, flex: 1, tooltipField: "message"},
    ]);

    const fetchProjectLogs = async () => {
        try {
            setLoading(true);
            const logs = await getProjectLogs(project.id);
            setLogs(logs);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!project || !visible) return;
        fetchProjectLogs().then();
    }, [visible]);

    if (user.role !== ROLES.TEACHER) return null;

    return <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setVisibility(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-25"/>
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel
                            className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                <div className={`flex flex-1 items-center justify-between`}>
                                    Project Logs
                                    <span className={`cursor-pointer`} onClick={() => setVisibility(false)}>
                                      <AiOutlineClose/>
                                      </span>
                                </div>
                            </Dialog.Title>
                            <div>
                                <br/>
                                {loading && <div className={`flex flex-1 items-center justify-center h-[200px]`}>
                                    Loading...
                                </div>}
                                {!loading && <div className="ag-theme-alpine" style={{height: 500, width: '100%'}}>
                                    <button
                                        className="border rounded md py-2 px-4 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
                                        onClick={exportToCsv}>Export to CSV { logs?.length > 0  && `(${logs.length} events)` }</button>
                                    <br />
                                    <br />
                                    <AgGridReact autoHeight={true}
                                                 onGridReady={onGridReady}
                                                 rowData={logs}
                                                 columnDefs={columnDefs}/>
                                    <br />
                                </div>}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
};

export default ProjectLogs;



