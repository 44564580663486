export const getMeanDegree = (cytoNodes) => {
  const degreeSum = cytoNodes.reduce(
    (sum, currNode) => sum + currNode.degree(),
    0
  );
  return degreeSum / cytoNodes.length;
};

const getCommonConcepts = (firstGraphNodes, secondGraphNodes) => {
  const visited = [];
  const commonConcepts = [];

  [...firstGraphNodes, ...secondGraphNodes].forEach((concept) => {
    const { label: conceptLabel } = concept;
    if (visited.includes(conceptLabel)) {
      commonConcepts.push(conceptLabel);
    } else {
      visited.push(conceptLabel);
    }
  });

  return commonConcepts;
};

const getRelationshipsWithLabels = (concepts, relationships) => {
  const conceptLabelById = concepts.reduce(
    (idMap, currConcept) => ({ ...idMap, [currConcept.id]: currConcept.label }),
    {}
  );

  return relationships.map((relationship) => ({
    ...relationship,
    from: conceptLabelById[relationship.from],
    to: conceptLabelById[relationship.to],
  }));
};

const getCommonRelationships = (
  firstGraphRelationships,
  secondGraphRelationships
) => {
  const visited = [];
  const commonRelationships = [];

  [...firstGraphRelationships, ...secondGraphRelationships].forEach(
    (relationship) => {
      const isVisited = visited.some(
        (otherRelationship) =>
          otherRelationship.from === relationship.from &&
          otherRelationship.to === relationship.to &&
          otherRelationship.label === relationship.label
      );
      if (isVisited) {
        commonRelationships.push({
          from: relationship.from,
          to: relationship.to,
          label: relationship.label,
        });
      } else {
        visited.push({
          from: relationship.from,
          to: relationship.to,
          label: relationship.label,
        });
      }
    }
  );

  return commonRelationships;
};

export const getConceptsDiff = (firstGraphNodes, secondGraphNodes) => {
  const common = getCommonConcepts(firstGraphNodes, secondGraphNodes);

  const onlyFirstHas = firstGraphNodes.filter(
    (concept) => !common.includes(concept.label)
  );

  const onlySecondHas = secondGraphNodes.filter(
    (concept) => !common.includes(concept.label)
  );

  return {
    common,
    onlyFirstHas,
    onlySecondHas,
  };
};

export const getRelationshipsDiff = (
  firstGraphEntities,
  secondGraphEntities
) => {
  const firstGraphRelationships = getRelationshipsWithLabels(
    firstGraphEntities.nodes,
    firstGraphEntities.edges
  );

  const secondGraphRelationships = getRelationshipsWithLabels(
    secondGraphEntities.nodes,
    secondGraphEntities.edges
  );

  const common = getCommonRelationships(
    firstGraphRelationships,
    secondGraphRelationships
  );

  const onlyFirstHas = firstGraphRelationships.filter(
    (relationship) =>
      !common.some(
        (currRelationship) =>
          currRelationship.from === relationship.from &&
          currRelationship.to === relationship.to &&
          currRelationship.label === relationship.label
      )
  );

  const onlySecondHas = secondGraphRelationships.filter(
    (relationship) =>
      !common.some(
        (currRelationship) =>
          currRelationship.from === relationship.from &&
          currRelationship.to === relationship.to &&
          currRelationship.label === relationship.label
      )
  );

  return {
    common,
    onlyFirstHas,
    onlySecondHas,
  };
};

export const getAvergaeShortestPath = (graphElements, graphNodes) => {
  const fw = graphElements.floydWarshall();

  let distancesCount = 0;

  for (const node of graphNodes) {
    const otherNodes = graphNodes.filter(
      (currNode) => currNode.data("id") !== node.data("id")
    );
    for (const otherNode of otherNodes) {
      const distanceToOtherNode = fw.distance(
        `[label = "${node.data("label")}"]`,
        `[label = "${otherNode.data("label")}"]`
      );
      distancesCount += Number.isFinite(distanceToOtherNode)
        ? distanceToOtherNode
        : 0;
    }
  }

  return distancesCount / (graphNodes.length * (graphNodes.length - 1));
};
