import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AiOutlineClose} from "react-icons/ai";

const ProjectHelp = ({ helpVisible, setHelpVisible }) => {
    return <Transition appear show={helpVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setHelpVisible(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                <div className={`flex flex-1 items-center justify-between`}>
                                    Instructions
                                    <span className={`cursor-pointer`} onClick={() => setHelpVisible(false)}>
                      <AiOutlineClose />
                      </span>
                                </div>
                            </Dialog.Title>
                            <div>
                                <br />
                                <p className="text-md text-gray-400">Edit mode</p>
                                <p className="text-black pt-2 text-sm">
                                    <ul>
                                        <li>
                                            - Navigating: Click-and-drag a blank space to pan the screen.  Use scroll-wheel or two-finger drag to zoom.
                                        </li>
                                        <li>
                                            - Adding concept: Double-click to add a new concept to the map.
                                        </li>
                                        <li>
                                            - Moving concept: Click-and-drag concepts to move them on the screen.
                                        </li>
                                        <li>
                                            - Creating relationship: To make a relationship between two concepts, click on the first concept ("Concept A"), click the "Connect to" button in the popup that appears, then click the second concept ("Concept B").
                                        </li>
                                        <li>
                                            - Editing concept/relationship: Click to edit.
                                        </li>
                                    </ul>
                                </p>
                                <br />
                                <p className="text-md text-gray-400">View mode</p>
                                <p className="text-black text-sm">
                                    <ul>
                                        <li>- Double click to show or hide children.</li>
                                        <li>- View concepts wiki info</li>
                                        <li>- Create/delete concepts</li>
                                        <li>- Create/edit/delete relationships</li>
                                        <li>
                                            - To create a relationship, click a concept, click "Connect
                                            to", then click another concept.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <br />
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
}

export default ProjectHelp;
