import { useState } from "react";
import { joinProject } from "../../../../../api/project";
import Popup from "../../../../shared/Popup";
import Spinner from "../../../../shared/spinner/Spinner";

const JoinProjectPopup = ({ show, onClose, onAddProject }) => {
  const [joining, setJoining] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [error, setError] = useState();

  const join = async () => {
    try {
      setError(null);
      setJoining(true);
      const newProject = await joinProject(projectId);
      onClose();
      onAddProject(newProject.id);
    } catch (err) {
      setError(err?.message ?? "You are already a member of this project");
      console.log(333, err);
    } finally {
      setJoining(false);
    }
  };

  return (
    <Popup
      title="Join Project"
      width={700}
      contentComponent={
        <div className="flex flex-col">
          {joining && (
            <div className="py-1">
              <Spinner text="Joining project..." />
            </div>
          )}
          {!joining && (
            <input
              id="projectId"
              name="projectId"
              onChange={(e) => setProjectId(e.target.value)}
              required
              className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Project ID"
            />
          )}
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-center text-red-700 px-4 py-3 mt-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
        </div>
      }
      onSubmit={join}
      onCancel={onClose}
      show={show}
      onClose={onClose}
      allowSubmit={!joining}
    />
  );
};

export default JoinProjectPopup;
