import NavBar from "../home/nav-bar/NavBar";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { getAllUsers, updateFirebaseUser, updateUser } from "../../../api/user";
import SuccessToast from "../../shared/toasts/successToast";
import { Button, Modal } from "flowbite-react";

const ManageUsers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [toast, setToast] = useState();
  const [disableModal, setDisableModal] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const disableUser = async () => {
    const userToUpdate = users.find(
      (user) => user.firebaseUid === currentUser.firebaseUid,
    );
    await updateFirebaseUser(userToUpdate.firebaseUid, {
      disabled: !userToUpdate.disabled,
    });
    setToast({ text: "User updated successfully" });
    setDisableModal(false);
    setCurrentUser(undefined);
    await fetchUsers();
  };

  const updateRole = async (firebaseUid, value) => {
    await updateUser(firebaseUid, value);
    setToast({ text: "User updated successfully" });
    await fetchUsers();
  };

  const [columnDefs] = useState([
    { field: "email", sortable: true, filter: true, width: 170 },
    {
      field: "name",
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: (params) => <div>{params.data.name}</div>,
    },
    { field: "disabled", sortable: true, filter: true, width: 120 },
    { field: "lastSignInTime", filter: true, flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      cellRenderer: (params) => (
        <div className={`flex gap-5`}>
          <div>
            {!params.data.disabled ? (
              <button
                className={`underline hover:text-indigo-500`}
                onClick={() => {
                  setCurrentUser(params.data);
                  setDisableModal(true);
                }}
              >
                Disable
              </button>
            ) : (
              <button
                className={`underline hover:text-indigo-500`}
                onClick={() => {
                  setCurrentUser(params.data);
                  setDisableModal(true);
                }}
              >
                Enable
              </button>
            )}
          </div>
          <div>
            <select
              name="roles"
              onChange={(e) => {
                updateRole(params.data.firebaseUid, { role: e.target.value });
              }}
              id="role-select"
              style={{
                height: "27px",
                padding: "0 0 0 10px",
                width: "140px",
              }}
            >
              <option value="STUDENT" selected={params.data.role === "STUDENT"}>
                Student
              </option>
              <option value="TEACHER" selected={params.data.role === "TEACHER"}>
                Teacher
              </option>
            </select>
          </div>
        </div>
      ),
    },
  ]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const users = await getAllUsers();
      setUsers(users);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers().then();
  }, []);

  return (
    <div className="min-h-full">
      <NavBar />
      {toast && (
        <SuccessToast
          text={toast.text}
          classes="bottom-1 left-4"
          onClose={() => setToast(undefined)}
        />
      )}
      <Modal
        show={disableModal}
        onClose={() => {
          setDisableModal(false);
          setCurrentUser(undefined);
        }}
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Footer>
          <Button onClick={disableUser}>
            {currentUser?.disabled ? "Enable" : "Disable"}
          </Button>
          <Button color="gray" onClick={() => setDisableModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="min-h-full flex flex-col">
        <header className="bg-white shadow">
          <div className="flex items-center gap-2 flex-wrap px-4 py-4">
            <span
              className={`ml-2 text-md text-gray-400 text-regular uppercase`}
            >
              Manage Users ({users.length})
            </span>
          </div>
        </header>
        {loading && (
          <div className={`flex flex-1 items-center justify-center h-[200px]`}>
            <br />
            Loading...
          </div>
        )}
        {!loading && (
          <div
            className="ag-theme-alpine"
            style={{ height: "500px", width: "100%" }}
          >
            <AgGridReact
              autoHeight={true}
              rowData={users}
              getRowNodeId={(data) => {
                console.log(data);
                return data.uid;
              }}
              columnDefs={columnDefs}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
