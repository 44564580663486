import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useUserAuth} from "../context/userAuthContext";

const RoleProtectedRoute = ({ role, children }) => {
  const navigate = useNavigate();
  const { user } = useUserAuth()

  useEffect(() => {
    if (user?.role && user?.role !== role) {
      navigate("/no-permission");
    }
  }, [user.role, role]);

  return children;
};

export default RoleProtectedRoute;
