import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUserAuth();

  if (loading) return null;
  if (!user) return <Navigate to="/login" />;

  return <>
    { children }
  </>
};

export default ProtectedRoute;
