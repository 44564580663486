import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

const ManageTriple = ({ visible, setVisible, onSubmit, updating, triple }) => {
  const [concept1, setConcept1] = useState(triple?.concept1 ?? "");
  const [concept2, setConcept2] = useState(triple?.concept2 ?? "");
  const [relationshipType, setRelationshipType] = useState(
    triple?.relationshipType ?? "",
  );
  const [score, setScore] = useState(triple?.score ?? "");

  useEffect(() => {
    setConcept1(triple?.concept1 ?? "");
    setConcept2(triple?.concept2 ?? "");
    setRelationshipType(triple?.relationshipType ?? "");
    setScore(triple?.score ?? "");
  }, [triple]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setVisible(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className={`flex flex-1 items-center justify-between`}>
                    Add Triple Score
                    <span
                      className={`cursor-pointer`}
                      onClick={() => setVisible(false)}
                    >
                      <AiOutlineClose />
                    </span>
                  </div>
                </Dialog.Title>
                <div>
                  <table className="min-w-full mt-1 mb-1">
                    <tbody>
                      <tr>
                        <td className="py-2">
                          <input
                            type="text"
                            value={concept1}
                            disabled={true}
                            onChange={(event) =>
                              setConcept1(event.target.value)
                            }
                            className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            placeholder="Enter source concept"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">
                          <input
                            type="text"
                            value={relationshipType}
                            disabled={true}
                            onChange={(event) =>
                              setRelationshipType(event.target.value)
                            }
                            className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            placeholder="Enter relationship"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">
                          <input
                            type="text"
                            value={concept2}
                            disabled={true}
                            onChange={(event) =>
                              setConcept2(event.target.value)
                            }
                            className={`form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm`}
                            placeholder="Enter target concept"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">
                          <input
                            type="text"
                            value={score}
                            onChange={(event) => setScore(event.target.value)}
                            className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            placeholder="Score"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={() =>
                      onSubmit({
                        concept1,
                        concept2,
                        relationshipType,
                        score,
                      })
                    }
                    disabled={
                      updating ||
                      concept1.length === 0 ||
                      concept2.length === 0 ||
                      relationshipType.length === 0
                    }
                    className="disabled:opacity-50 group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {updating ? "Updating..." : "Update"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManageTriple;
