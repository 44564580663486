import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import {getUrlByTitle, getValueInfoByTitle} from "../../../../../api/wikipedia-api";
import Button from "../../../button/Button";
import Spinner from "../../../spinner/Spinner";
import WikipediaValue from "../wikipedia-value/WikipediaValue";

const ViewConceptDialog = ({
  concept,
  onClose,
  position,
  onUpdate,
  onHide,
  onDelete,
  onConnect
}) => {
  const [additionalValueInfo, setAdditionalValueInfo] = useState();
  const [loading, setLoading] = useState(false);

  const fetchAdditionalInfo = async (selectedValue) => {
    setLoading(true);
    const info = await getValueInfoByTitle(selectedValue);
    setAdditionalValueInfo(info);
    setLoading(false);
  };

  useEffect(() => {
    if (concept) {
      fetchAdditionalInfo(concept.data.label);
    }
  }, [concept]);

  const goToWikipediaLink = () => {
    window
        .open(getUrlByTitle(additionalValueInfo.label), "_blank")
        .focus();
  };


  return (
    <Draggable bounds="parent">
      <div
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
        className={`absolute rounded-md w-[400px] shadow z-20 flex flex-col bg-white p-3 cursor-grab`}
      >
        <div className="flex flex-col relative min-h-[100px]">
          {loading && <Spinner />}
          {additionalValueInfo && (
            <>
              <WikipediaValue
                classes="mb-3"
                wikipediaValue={additionalValueInfo}
                showWikipedialink={false}
                showCloseButton={true}
                onClose={onClose}
              />
            </>
          )}
        </div>
        <div className="flex items-center gap-2">
          { onConnect && <Button label="Connect to" onClick={onConnect} /> }
          { onHide && <Button label="Hide" onClick={onHide} /> }
          <Button label="Wiki" onClick={goToWikipediaLink} />
          { onDelete && <Button classes={`text-red-600 border-red-600`} label="Delete" onClick={onDelete} /> }
        </div>
      </div>
    </Draggable>
  );
};

export default ViewConceptDialog;
