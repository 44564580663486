import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/logo.png";
import User from "../../../../assets/user.svg";
import { useUserAuth } from "../../../../context/userAuthContext";
import { ROLES } from "../../../../utils/entities";
import { classNames } from "../../../../utils/helpers";

const NavBar = () => {
  const { logOut } = useUserAuth();
  const navigate = useNavigate();
  const { user } = useUserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  const goToProjectsPage = () => {
    if (user?.role === ROLES.TEACHER) {
      navigate("/teacher");
    } else {
      navigate("/student");
    }
  };

  const menuItems = [
    { name: "Projects", onClick: goToProjectsPage },
    { name: "Gradings", onClick: () => navigate("/gradings") },
    {
      name: "Account",
      onClick: () => {
        navigate("/account");
      },
    }
  ];

  if (user?.role === ROLES.TEACHER) {
    menuItems.push({
      name: "Manage Users",
      onClick: () => {
        navigate("/teacher/manage-users");
      },
    })
  }

  menuItems.push({ name: "Sign out", onClick: handleLogout });

  return (
    <nav
      className="flex items-center justify-between flex-wrap px-6 py-2 z-50"
      style={{ background: "#1f2937" }}
    >
      <div
          onClick={goToProjectsPage}
          className="flex items-center flex-shrink-0 text-white mr-6 gap-2 cursor-pointer">
        <img className="h-8 w-8" src={Logo} alt="Zotgraph" />
        <span className="text-white font-bold text-xl">ZotGraph</span>
      </div>
      <div className={'flex items-center'}>
        <span className={'text-[#bec6d1]'}>
          {user.email} - { user.name }
        </span>
        <Menu as="div" className="relative ml-3 z-20">
          <div>
            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={User}
                alt=""
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {menuItems.map((menuItem) => (
                <Menu.Item key={menuItem.name}>
                  {({ active }) => (
                    <span
                      onClick={menuItem.onClick}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      )}
                    >
                      {menuItem.name}
                    </span>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};

export default NavBar;
