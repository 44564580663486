import React, { createContext, useContext } from "react";
import Pusher from "pusher-js";

const PusherContext = createContext();

export const PusherProvider = ({ children }) => {
  const pusher = new Pusher("0c0e7b2ffdcdda305d53", {
    cluster: "us2",
    encrypted: true,
  });

  return (
    <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
  );
};

export const usePusher = () => useContext(PusherContext);
