import cytoscape from "cytoscape";
import cytoscapeKlay from "cytoscape-klay";
import * as _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { exportMap, sendMapEvents } from "../../../api/map";
import { usePrevious } from "../../../hooks/use-previous";
import ConfirmationPopup from "../confirmation-popup/ConfirmationPopup";
import LayoutPopup from "../layout-popup/LayoutPopup";
import SuccessToast from "../toasts/successToast";
import ActionsPanel from "./actions-panel/ActionsPanel";
import CreateConceptDialog from "./concept-controls/create-concept-dialog/CreateConceptDialog";
import ViewConceptDialog from "./concept-controls/view-concept-dialog/ViewConceptDialog";
import CytoInfoPanel from "./cyto-info-panel/CytoInfoPanel";
import { styleSheet } from "./cyto-style-sheet";
import { EventEntity, EventType } from "./event";
import { layouts } from "./layouts";
import MetricsDialog from "./metrics-dialog/MetricsDialog";
import CreateRelationshipDialog from "./relationship-controls/create-relationship-dialog/CreateRelationshipDialog";
import {
  getConceptsToHideWhenCollapse,
  getConceptsToShowWhenExpand,
  getConceptsToShowWhenExpandAll,
} from "./utils/expand-collapse-helpers";
import { useSetRecoilState } from "recoil";
import { zoomState } from "../../../store/project";

cytoscape.use(cytoscapeKlay);

const addEventListener = (reference, entity, type, callback) => {
  if (reference) {
    let existingClickCallback;
    if (entity) {
      existingClickCallback = reference
        .emitter()
        .listeners.find(
          (event) =>
            event.type === type && event.qualifier.inputText === entity,
        );
    } else {
      existingClickCallback = reference
        .emitter()
        .listeners.find((event) => event.type === type && !event.qualifier);
    }

    if (existingClickCallback) {
      existingClickCallback.callback = callback;
    } else {
      if (!entity) {
        reference.on(type, callback);
      } else {
        reference.on(type, entity, callback);
      }
    }
  }
};

const Cyto = forwardRef(
  (
    {
      nodes,
      edges,
      mapId,
      mapName,
      parentMapEntities,
      editable = true,
      showMetrics = true,
      requireCitations = false,
      allowEdit = true,
      project,
    },
    ref,
  ) => {
    const [editMode, setEditMode] = useState(editable);
    const [searchMode, setSearchMode] = useState(false);
    const [showCitationsMode, setShowCitationsMode] = useState(false);
    const [controlHeld] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [isLayoutDialogOpen, setIsLayoutDialogOpen] = useState(false);

    const layout = layouts.klay;

    const [deleteRelationshipModalVisible, setDeleteRelationshipModalVisible] =
      useState(false);
    const [deleteConceptModalVisible, setDeleteConceptModalVisible] =
      useState(false);
    const viewConceptOpenTimeout = useRef(null);
    const [toast, setToast] = useState();

    const [isCreateConceptDialogOpen, setIsCreateConceptDialogOpen] =
      useState(false);
    const [isConceptViewDialogOpen, setIsConceptViewDialogOpen] =
      useState(false);
    const [isCreateRelationshipDialogOpen, setIsCreateRelationshipDialogOpen] =
      useState(false);
    const [isMetricsDialogOpen, setIsMetricsDialogOpen] = useState(false);

    const [originNodes, setOriginNodes] = useState(nodes);
    const [originEdges, setOriginEdges] = useState(edges);
    const [newNodes, setNewNodes] = useState([]);
    const [newEdges, setNewEdges] = useState([]);

    const [selectedConcept, setSelectedConcept] = useState();
    const [selectedRelationship, setSelectedRelationship] = useState();
    const [selectedPosition, setSelectedPosition] = useState();
    const [selectedConcepts, setSelectedConcepts] = useState([]);
    const [tappedPosition, setTappedPosition] = useState();

    // Cytoscape

    const cy = useRef(null);
    const boardRef = useRef();

    const setCytoscape = useCallback(
      (ref) => {
        cy.current = ref;
        cy.current.minZoom(0.05);
        cy.current.maxZoom(4);
      },
      [cy],
    );

    const convertedNodes = useMemo(
      () =>
        [...originNodes, ...newNodes].map((node, index) => ({
          data: {
            id: node.id,
            label: node.label,
            origin: node.origin,
            original: index < originNodes.length,
            visible: node.visible === undefined ? true : node.visible,
            expanded: node.expanded === undefined ? true : node.expanded,
          },
          renderedPosition:
            Array.isArray(node.position) && node.position[0]
              ? { x: node.position[0], y: node.position[1] }
              : undefined,
        })),
      [originNodes, newNodes],
    );
    const previousConvertedNodes = usePrevious(convertedNodes);

    const convertedEdges = useMemo(
      () =>
        [...originEdges, ...newEdges].map((edge, index) => ({
          data: {
            id: edge.elementId || index.toString(),
            source: edge.from,
            target: edge.to,
            label: edge.label,
            citation: edge.citation,
            origin: edge.origin,
            original: index < originEdges.length,
            citations: edge.citations,
          },
        })),
      [originEdges, newEdges],
    );

    const getVisibleNodes = useMemo(
      () => convertedNodes.filter((node) => node.data.visible) || [],
      [convertedNodes],
    );

    const getVisibleEdges = cy.current
      ? cy.current.edges().filter((edge) => edge.style("display") === "element")
      : [];

    const elements = useMemo(
      () =>
        CytoscapeComponent.normalizeElements({
          nodes: convertedNodes,
          edges: convertedEdges,
        }),
      [convertedNodes, convertedEdges],
    );

    const getConceptReferenceById = (id) => {
      return cy?.current?.filter(`node[id = '${id}']`)[0];
    };

    const getConceptConvertedById = (id) => {
      return convertedNodes.find(
        (concept) => concept.data.id.toString() === id.toString(),
      );
    };

    const getPositionBetweenConcepts = (concepta, conceptb) => {
      return {
        x: Math.abs(
          (concepta.renderedPosition().x + conceptb.renderedPosition().x) / 2,
        ),
        y: Math.abs(
          (concepta.renderedPosition().y + conceptb.renderedPosition().y) / 2,
        ),
      };
    };

    const deselectConcepts = () => {
      cy.current.nodes().forEach((node) => node.unselect());
      setSelectedConcept(null);
    };

    const fitCytoAroundLastCreatedConcept = () => {
      const cytoNodes = cy.current.nodes();

      focusOnConcept(cytoNodes[cytoNodes.length - 1].data("id"));
    };

    // use this to center the map from outside of this component
    // by calling ref.current.center() from a parent component
    useImperativeHandle(ref, () => ({
      center: () => {
        cy.current
          ?.layout({
            fit: true,
          })
          .run();
        cy.current?.center();
      },
    }));

    // center map on initial load
    useEffect(() => {
      cy.current?.center();
    }, []);

    const setZoom = useSetRecoilState(zoomState);

    cy.current?.ready(function () {
      // cy.current?.center();
      // console.log('\n'.repeat('25'));
      // console.log('ready', Math.random())
      cy.current.on("zoom", () => {
        setZoom(cy.current.zoom());
      });
    });

    const fitCyto = () => {
      cy.current
        ?.layout({
          name: "preset",
          animationDuration: 210,
          animate: true,
          fit: true,
        })
        .run();
    };

    const dynamicStyleSheet = useMemo(
      () => [
        ...styleSheet(project),
        {
          selector: "edge[label]",
          style: {
            label: (edge) => {
              const label = edge.data("label");
              if (!showCitationsMode) {
                return label;
              }

              const uniqCitations = _.uniq(edge.data("citations") || []);
              const citations = uniqCitations
                .slice(0, 3)
                .map((citation) => `"${citation}"`)
                .join("\n");
              const moreExtension =
                uniqCitations.length > 3
                  ? `\n(+${uniqCitations.length - 3} more}`
                  : "";
              return `${label}\n${citations}${moreExtension}`;
            },
            "font-size": "12",
            "text-wrap": "wrap",
            "text-background-color": editMode ? "lightblue" : "#f1f1f1",
            "text-background-opacity": 1,
            "text-background-padding": "4px",
            "text-margin-y": -4,
            "text-events": "yes",
          },
        },
      ],
      [showCitationsMode, editMode],
    );

    const focusOnConcept = (conceptId) => {
      const conceptRef = getConceptReferenceById(conceptId);
      if (conceptRef) {
        cy.current?.animate({
          fit: { eles: conceptRef },
          duration: 200,
        });
      }
    };

    // Actions
    const createConcept = (concept, position) => {
      const id = getRandomFreeId();
      const newConcept = {
        id,
        label: concept.label,
        position: [position.x, position.y],
      };
      setSelectedConcept(newConcept);
      setNewNodes((oNodes) => [...oNodes, newConcept]);
      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Create,
          identifiers: [],
          values: {
            id,
            label: concept.label,
            position: tappedPosition,
            visible: true,
            expanded: true,
          },
        },
      ]);
    };

    const collapseConcept = (concept) => {
      const conceptsToHide = getConceptsToHideWhenCollapse(
        cy.current.nodes(),
        concept.id,
      );

      updateConceptsBatch([
        {
          conceptsIdsToUpdate: conceptsToHide.map((concept) => concept.id),
          change: { visible: false },
        },
        { conceptsIdsToUpdate: [concept.id], change: { expanded: false } },
      ]);
      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          values: {
            visible: false,
          },
          identifiers: conceptsToHide.map((concept) => concept.label),
        },
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          values: {
            expanded: false,
          },
          identifiers: [concept.label],
        },
      ]);
    };

    const expandConcept = (concept) => {
      const conceptsToShow = getConceptsToShowWhenExpand(
        cy.current.nodes(),
        concept.id,
      );

      updateConceptsBatch([
        {
          conceptsIdsToUpdate: conceptsToShow.map((concept) => concept.id),
          change: { visible: true },
        },
        { conceptsIdsToUpdate: [concept.id], change: { expanded: true } },
      ]);
      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          values: {
            visible: true,
          },
          identifiers: conceptsToShow.map((concept) => concept.label),
        },
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          values: {
            expanded: true,
          },
          identifiers: [concept.label],
        },
      ]);
    };

    const hideConcept = () => {
      const { id: conceptId, label } = selectedConcept.data;
      updateConcepts([conceptId], { visible: false });
      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          identifiers: [label],
          values: { visible: false },
        },
      ]);
      setIsConceptViewDialogOpen(false);
      setSelectedConcept(undefined);
      setSelectedConcepts([]);
    };

    const deleteConcept = () => {
      setNewNodes((newOnes) =>
        newOnes.filter((concept) => +concept.id !== +selectedConcept.data.id),
      );
      setOriginNodes((oldOnes) =>
        oldOnes.filter((concept) => +concept.id !== +selectedConcept.data.id),
      );
      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Delete,
          identifiers: [selectedConcept.data.label],
          values: {},
        },
      ]);
      setIsConceptViewDialogOpen(false);
      setSelectedConcept(undefined);
      setSelectedConcepts([]);
    };

    const showAllConcepts = async () => {
      updateConcepts(
        convertedNodes.map((concept) => concept.data.id),
        { visible: true },
      );
      await sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          identifiers: convertedNodes.map((concept) => concept.data.label),
          values: {
            visible: true,
          },
        },
      ]);
      fitCyto();
    };

    const expandAllConcepts = () => {
      const conceptsToShow = getConceptsToShowWhenExpandAll(cy.current.nodes());

      updateConcepts(
        conceptsToShow.map((concept) => concept.id),
        { visible: true, expanded: true },
      );

      sendEvents([
        {
          entity: EventEntity.Concept,
          type: EventType.Update,
          identifiers: conceptsToShow.map((concept) => concept.label),
          values: {
            visible: true,
            expanded: true,
          },
        },
      ]);
      fitCyto();
    };

    const exportCurrentMap = async () => {
      await exportMap(mapId);
    };

    const getRandomFreeId = () => {
      let randomId = Math.floor(Math.random() * 100000000);
      while (
        convertedNodes.some(
          (concept) => concept.data.id.toString() === randomId.toString(),
        )
      ) {
        randomId = Math.floor(Math.random() * 100000000);
      }
      return randomId;
    };

    const createTemporarilyRelationship = (relationship) => {
      setNewEdges((oEdges) => [
        ...oEdges,
        { ...relationship, temporarily: true },
      ]);
    };

    const deleteTemporarilyRelationship = () => {
      setNewEdges((oEdges) => oEdges.filter((edge) => !edge.temporarily));
    };

    const createRelationship = (relationship) => {
      deleteTemporarilyRelationship();
      setNewEdges((oEdges) => [...oEdges, relationship]);

      sendEvents([
        {
          entity: EventEntity.Relationship,
          type: EventType.Create,
          values: relationship,
          identifiers: [
            {
              from: convertedNodes?.find(
                (node) =>
                  node.data.id.toString() === relationship.from.toString(),
              )?.data?.label,
              to: convertedNodes?.find(
                (node) =>
                  node.data.id.toString() === relationship.to.toString(),
              )?.data?.label,
            },
          ],
        },
      ]);
    };

    const updateRelationship = (newRelationship) => {
      if (selectedRelationship.data.original) {
        setOriginEdges((edges) =>
          edges.map((edge) => {
            return edge.elementId === newRelationship.id
              ? {
                  ...edge.data,
                  ...newRelationship,
                  elementId: edge.elementId,
                  id: newRelationship.id,
                }
              : edge;
          }),
        );
      } else {
        setNewEdges((edges) =>
          edges.map((edge, index) =>
            (index + originEdges.length).toString() ===
            newRelationship.id.toString()
              ? { ...edge.data, ...newRelationship }
              : edge,
          ),
        );
      }
      setSelectedRelationship(undefined);
      setSelectedConcepts([]);
      sendEvents([
        {
          entity: EventEntity.Relationship,
          type: EventType.Update,
          values: newRelationship,
          identifiers: [
            {
              from: convertedNodes?.find(
                (node) => node.data.id === newRelationship.from,
              ).data.label,
              to: convertedNodes?.find(
                (node) => node.data.id === newRelationship.to,
              ).data.label,
            },
          ],
        },
      ]);
    };

    const deleteRelationship = () => {
      setOriginEdges((oEdges) =>
        oEdges.filter(
          (edge) =>
            !(
              edge.from === selectedRelationship.data.source &&
              edge.to === selectedRelationship.data.target &&
              edge.label === selectedRelationship.data.label
            ),
        ),
      );

      setNewEdges((oEdges) =>
        oEdges.filter(
          (edge) =>
            !(
              edge.from === selectedRelationship.data.source &&
              edge.to === selectedRelationship.data.target &&
              edge.label === selectedRelationship.data.label
            ),
        ),
      );

      sendEvents([
        {
          entity: EventEntity.Relationship,
          type: EventType.Delete,
          values: {
            from: selectedRelationship.data.source,
            to: selectedRelationship.data.target,
            label: selectedRelationship.data.label,
          },
          identifiers: [
            {
              from: convertedNodes?.find(
                (node) => node.data.id === selectedRelationship.data.source,
              ).data.label,
              to: convertedNodes?.find(
                (node) => node.data.id === selectedRelationship.data.target,
              ).data.label,
            },
          ],
        },
      ]);

      cy.current.edges().forEach((edge) => edge.unselect());
      setSelectedRelationship(undefined);
      setSelectedConcepts([]);
      setSelectedConcept(undefined);
      setIsConceptViewDialogOpen(false);
      setIsCreateRelationshipDialogOpen(false);

      updateConcepts(
        convertedNodes.map((concept) => concept.data.id),
        {},
      );
    };

    const updateConcepts = (conceptsIdsToUpdate, change) => {
      setOriginNodes((nodes) =>
        nodes.map((node) =>
          conceptsIdsToUpdate.includes(node.id.toString())
            ? { ...node, ...change }
            : node,
        ),
      );
      setNewNodes((nodes) =>
        nodes.map((node) =>
          conceptsIdsToUpdate.includes(node.id.toString())
            ? { ...node, ...change }
            : node,
        ),
      );
    };

    const updateConceptsBatch = (updates) => {
      setOriginNodes((nodes) =>
        nodes.map((node) => {
          let originalNode = { ...node };

          updates.forEach((update) => {
            if (update.conceptsIdsToUpdate.includes(node.id.toString())) {
              originalNode = { ...originalNode, ...update.change };
            }
          });

          return originalNode;
        }),
      );
      setNewNodes((nodes) =>
        nodes.map((node) => {
          let originalNode = { ...node };

          updates.forEach((update) => {
            if (update.conceptsIdsToUpdate.includes(node.id.toString())) {
              originalNode = { ...originalNode, ...update.change };
            }
          });

          return originalNode;
        }),
      );
    };

    const openCreateRelationshipDialog = (conceptsIds, isNew = false) => {
      const originReference = getConceptReferenceById(conceptsIds[0]);
      const destinationReference = getConceptReferenceById(conceptsIds[1]);
      setSelectedConcepts([
        {
          data: originReference.data(),
          renderedPosition: originReference.renderedPosition,
        },
        {
          data: destinationReference.data(),
          renderedPosition: destinationReference.renderedPosition,
        },
      ]);
      const centerPoint = getPositionBetweenConcepts(
        originReference,
        destinationReference,
      );
      if (isNew) {
        createTemporarilyRelationship({
          from: conceptsIds[0],
          to: conceptsIds[1],
          label: "",
        });
      }
      setSelectedPosition(centerPoint);
      setIsCreateRelationshipDialogOpen(true);
    };

    const sendEvents = async (events) => {
      await sendMapEvents(project.id, mapId, events);
    };

    const changeZoomLevel = (zoomRate) => {
      const newZoom = parseFloat(zoomRate);
      cy.current.zoom({
        level: newZoom,
        animate: true,
        position: { x: cy.current.width() / 2, y: cy.current.height() / 2 },
      });
    };

    const discardCreateRelationship = () => {
      deleteTemporarilyRelationship();
      setIsCreateRelationshipDialogOpen(false);
      deselectConcepts();
      setSelectedRelationship(null);
      setSelectedPosition(null);
      setSelectedConcepts([]);
    };

    // Handle clicks
    const handleDoubleClick = useCallback(
      (event) => {
        // It's a double click
        if (event.detail === 2) {
          if (
            !isConceptViewDialogOpen &&
            !isCreateConceptDialogOpen &&
            !isCreateRelationshipDialogOpen &&
            editMode
          ) {
            setSelectedPosition({
              x: event.clientX - boardRef.current.offsetLeft,
              y: event.clientY - boardRef.current.offsetTop,
            });
            if (allowEdit) setIsCreateConceptDialogOpen(true);
          }
        }
      },
      [
        editMode,
        isConceptViewDialogOpen,
        isCreateConceptDialogOpen,
        isCreateRelationshipDialogOpen,
        allowEdit,
        tappedPosition,
      ],
    );

    const onConceptDoubleClick = (selectedConcept) => {
      if (editMode) return;
      const { id, expanded, label } = selectedConcept.data();
      if (expanded) {
        collapseConcept({ id, label });
      } else {
        expandConcept({ id, label });
        if (!editMode) flower(selectedConcept);
      }
    };

    const flower = (selectedConcept) => {
      const neighbors = selectedConcept.neighborhood("node{degree = 1}"); //.add(selectedConcept);

      const angleStep = (2 * Math.PI) / neighbors.length;

      // Define the radius of the circle
      const radius = 200;

      // Store the positions of the neighbors
      const neighborPositions = {};

      // Calculate the positions of the neighbors around the clicked node
      neighbors.forEach(function (neighbor, index) {
        const angle = index * angleStep;
        const x = selectedConcept.position("x") + radius * Math.cos(angle);
        const y = selectedConcept.position("y") + radius * Math.sin(angle);
        neighborPositions[neighbor.id()] = { x: x, y: y };
      });

      cy.current.batch(function () {
        neighbors.nodes().positions(function (node) {
          if (node.data("visible") === false) {
            return neighborPositions[node.id()];
          }
        });
      });
    };

    const clearViewDialogTimeout = () => {
      clearTimeout(viewConceptOpenTimeout.current);
      viewConceptOpenTimeout.current = null;
    };

    const onConceptClick = (selectedConcept) => {
      const convertedReference = getConceptConvertedById(selectedConcept.id());
      if (selectedConcept) {
        // Edit mode - click node to connect it to another
        if (!editMode) {
          setSelectedConcept(convertedReference);
          setSelectedPosition(selectedConcept.renderedPosition());
        } else {
          if (selectedConcepts.length === 0) {
            setSelectedConcepts([convertedReference]);
            setIsConceptViewDialogOpen(true);
            setSelectedConcept(convertedReference);
            setSelectedPosition(selectedConcept.renderedPosition());
          } else if (selectedConcepts.length === 1) {
            const conceptsToConnect = [selectedConcepts[0], convertedReference];
            setSelectedConcepts(conceptsToConnect);

            // disallow self-connections
            if (conceptsToConnect[0].data.id === conceptsToConnect[1].data.id)
              return;

            if (!connecting) {
              setConnecting(false);
              setSelectedConcepts([]);
              setSelectedConcept(convertedReference);
              setSelectedPosition(selectedConcept.renderedPosition());
              setIsConceptViewDialogOpen(true);
              setSelectedRelationship(undefined);
              setIsCreateConceptDialogOpen(false);
              setIsCreateRelationshipDialogOpen(false);
              return;
            }

            openCreateRelationshipDialog(
              [conceptsToConnect[0].data.id, conceptsToConnect[1].data.id],
              true,
            );
          }
        }
      }
    };

    const handleConceptClick = useCallback(
      (event) => {
        const clickDelay = 200;
        const selectedConcept = getConceptReferenceById(event.target.id());

        if (!viewConceptOpenTimeout.current) {
          viewConceptOpenTimeout.current = setTimeout(
            () => setIsConceptViewDialogOpen(true),
            clickDelay,
          );
        }

        // Double click
        if (selectedConcept.selected()) {
          clearViewDialogTimeout();
          onConceptDoubleClick(selectedConcept);
          return;
        }

        if (editMode) {
          clearViewDialogTimeout();
          onConceptClick(selectedConcept);
          return;
        }

        // view mode
        onConceptClick(selectedConcept);
        setTimeout(() => clearViewDialogTimeout(), clickDelay);
      },
      [
        editMode,
        selectedConcepts,
        convertedNodes,
        controlHeld,
        connecting,
        viewConceptOpenTimeout.current,
      ],
    );

    const handleRelationshipClick = useCallback(
      (event) => {
        const existingEdges = convertedEdges;
        const { id: relationshipId } = event.target.data();
        const selectedRelationship = existingEdges.find(
          (edge) => edge.data.id.toString() === relationshipId.toString(),
        );

        if (selectedRelationship && editMode && allowEdit) {
          setSelectedRelationship(selectedRelationship);
          openCreateRelationshipDialog([
            selectedRelationship.data.source,
            selectedRelationship.data.target,
          ]);
        }
      },
      [convertedEdges, editMode, allowEdit],
    );

    useEffect(() => {
      setOriginNodes(nodes);
    }, [nodes]);

    useEffect(() => {
      setOriginEdges(edges);
    }, [edges]);

    useEffect(() => {
      if (!editMode) {
        setSelectedConcepts([]);
      } else {
        setIsConceptViewDialogOpen(false);
        deselectConcepts();
      }
    }, [editMode]);

    useEffect(() => {
      deselectConcepts();
    }, [convertedNodes, previousConvertedNodes]);

    useEffect(() => {
      deselectConcepts();
      cy.current.edges().forEach((edge) => edge.unselect());
      setIsMetricsDialogOpen(false);
      setIsCreateConceptDialogOpen(false);
      setIsConceptViewDialogOpen(false);
      setIsCreateRelationshipDialogOpen(false);

      // cy.current.style().selector('edge').style({
      //   'line-color': 'blue'
      // }).update();
    }, [editMode]);

    useEffect(() => {
      if (cy?.current) {
        addEventListener(cy.current, "node", "click", handleConceptClick);
        addEventListener(cy.current, "node", "dragfree", (e) => {
          if (!allowEdit) return;
          if (!editMode) return;
          sendEvents([
            {
              entity: EventEntity.Concept,
              type: EventType.Update,
              identifiers: [e.target[0].data("label")],
              values: { position: e.target[0].position() },
            },
          ]);
        });
        addEventListener(cy.current, "edge", "click", handleRelationshipClick);
        addEventListener(cy.current, undefined, "click", (event) => {
          setTappedPosition(event.position);
          if (event.target === cy.current) {
            setConnecting(false);
            cy.current.edges().forEach((edge) => edge.unselect());
            setIsConceptViewDialogOpen(false);
            setSelectedRelationship(undefined);
            setIsCreateConceptDialogOpen(false);
            setIsCreateRelationshipDialogOpen(false);
            discardCreateRelationship();
            setSelectedConcepts([]);
            setSelectedConcept(undefined);
          }
        });
      }
    }, [cy, handleConceptClick, handleRelationshipClick, editMode]);

    const runLayout = (layout) => {
      if (!editMode) return;
      cy.current
        ?.layout({
          name: layout,
          fit: true,
          avoidOverlap: true,
          nodeDimensionsIncludeLabels: true,
          nodeSep: 20,
        })
        .run();
    };

    return (
      <div
        style={{ position: "relative", width: "100%", height: "100%" }}
        ref={boardRef}
        onClick={handleDoubleClick}
      >
        {mapName && (
          <button
            disabled
            className="absolute bottom-3 left-3 z-20 bg-[#dfdfdf] py-2 px-4 text-sm border border-[#e5e7eb] rounded flex items-center justify-center text-sm text-gray-800 font-semibold"
          >
            {mapName}
          </button>
        )}
        <ActionsPanel
          onExport={exportCurrentMap}
          onShowAll={showAllConcepts}
          onExpandAll={expandAllConcepts}
          onEditChange={() => setEditMode((oldStatus) => !oldStatus)}
          {...(requireCitations && {
            onShowCitationsChange: (newStatus) =>
              setShowCitationsMode(newStatus),
          })}
          mapName={mapName}
          showCitationsModeOn={showCitationsMode}
          editModeOn={editMode}
          editable={editable && allowEdit}
          search={{
            searchModeOn: searchMode,
            onSearchModeChange: () => {
              setSearchMode((oldStatus) => !oldStatus);
            },
            searchOptions: cy.current?.nodes().map((node) => ({
              id: node.data("id"),
              label: node.data("label"),
            })),
            onSearchSelect: (selectedConcept) => {
              const { label } = selectedConcept;
              updateConcepts([selectedConcept.id], { visible: true });
              sendEvents([
                {
                  entity: EventEntity.Concept,
                  type: EventType.Update,
                  identifiers: [label],
                  values: { visible: true },
                },
              ]);
              focusOnConcept(selectedConcept.id);
            },
          }}
          onShowLayoutDialog={() => setIsLayoutDialogOpen(true)}
          onZoomChange={changeZoomLevel}
        />
        {editable &&
          isCreateRelationshipDialogOpen &&
          selectedConcepts.length > 0 && (
            <CreateRelationshipDialog
              concepts={selectedConcepts}
              allowEdit={allowEdit}
              position={selectedPosition}
              onCreate={createRelationship}
              onUpdate={updateRelationship}
              onDelete={() => setDeleteRelationshipModalVisible(true)}
              onClose={discardCreateRelationship}
              relationship={selectedRelationship}
            />
          )}
        {editMode && isCreateConceptDialogOpen && selectedPosition && (
          <CreateConceptDialog
            position={selectedPosition}
            onCreate={createConcept}
            onClose={() => {
              setIsCreateConceptDialogOpen(false);
              setSelectedPosition(null);
              deselectConcepts();
              setSelectedConcept(null);
            }}
            skipValues={convertedNodes.map((concept) => concept.data.label)}
          />
        )}
        {selectedConcept && isConceptViewDialogOpen && selectedPosition && (
          <ViewConceptDialog
            concept={selectedConcept}
            position={selectedPosition}
            onClose={() => {
              deselectConcepts();
              setSelectedConcept(null);
              setSelectedConcepts([]);
              setIsConceptViewDialogOpen(false);
            }}
            onHide={editMode && allowEdit ? null : hideConcept}
            onDelete={
              editMode && allowEdit
                ? () => setDeleteConceptModalVisible(true)
                : null
            }
            onConnect={
              editMode && allowEdit
                ? () => {
                    setConnecting(true);
                    setIsConceptViewDialogOpen(false);
                  }
                : null
            }
          />
        )}
        {
          <ConfirmationPopup
            show={deleteRelationshipModalVisible}
            content={`Are you sure you want to delete this relationship?`}
            onClose={() => setDeleteRelationshipModalVisible(false)}
            onConfirm={deleteRelationship}
            onReject={() => setDeleteRelationshipModalVisible(false)}
          />
        }
        {
          <ConfirmationPopup
            show={deleteConceptModalVisible}
            content={`Are you sure you want to delete this concept?`}
            onClose={() => setDeleteConceptModalVisible(false)}
            onConfirm={deleteConcept}
            onReject={() => setDeleteConceptModalVisible(false)}
          />
        }
        {isLayoutDialogOpen && (
          <LayoutPopup
            show={isLayoutDialogOpen}
            run={runLayout}
            onCancel={() => setIsLayoutDialogOpen(false)}
          />
        )}
        {showMetrics && isMetricsDialogOpen && (
          <MetricsDialog
            currentGraphElements={{
              nodes: [...nodes, ...newNodes],
              edges: [...edges, ...newEdges],
              cyNodes: cy.current?.nodes(),
              cyEdges: cy.current?.edges(),
              elements: cy.current?.elements(),
            }}
            comparedGraphElements={parentMapEntities}
            onClose={() => setIsMetricsDialogOpen(false)}
          />
        )}
        <CytoscapeComponent
          id="cy"
          cy={setCytoscape}
          elements={elements}
          zoomingEnabled={!isConceptViewDialogOpen}
          // layout={customLayout}
          stylesheet={dynamicStyleSheet}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: editMode ? "#d4eaff6e" : "#f1f1f1",
          }}
        />
        <CytoInfoPanel
          nodesCount={getVisibleNodes.length}
          relationshipsCount={getVisibleEdges.length}
          showMetrics={showMetrics}
          onClick={() => setIsMetricsDialogOpen((value) => !value)}
        />
        {toast && (
          <SuccessToast
            text={toast.text}
            classes="bottom-1 left-4"
            onClose={() => setToast(undefined)}
          />
        )}
      </div>
    );
  },
);

export default Cyto;
