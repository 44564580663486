import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMapById } from "../../../api/map";
import Cyto from "../../shared/cyto/Cyto";
import NavBar from "../home/nav-bar/NavBar";

const ConceptMap = () => {
  const { mapId } = useParams();
  const [map, setMap] = useState();

  const fetchMap = async () => {
    const map = await getMapById(mapId);
    setMap(map);
  };

  useEffect(() => {
    fetchMap();
  }, []);

  return (
    <div className="min-h-full min-w-full flex flex-col">
      <NavBar />
      <div className="flex flex-col flex-1">
        <header className="bg-white shadow z-10">
          <div className="flex flex-col justify-center flex-wrap px-6 py-4 ext-gray-900">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Concept Map
            </h1>
          </div>
        </header>
        <div className="flex-1 flex">
          <div className="flex-1 rounded-lg bg-white border-2 border-dashed border-gray-200">
            {map && (
              <Cyto
                mapId={map.id}
                nodes={map.entities.concepts}
                edges={map.entities.relationships}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConceptMap;
