export const layouts = {
  random: {
    name: "random",
    animate: true,
  },
  grid: {
    name: "grid",
    animate: true,
  },
  circle: {
    name: "circle",
    animate: true,
  },
  breadthfirst: {
    name: "breadthfirst",
    animate: true,
  },
  klay: {
    name: "klay",
    animate: true,
    padding: 100,
    klay: {
      spacing: 150,
      mergeEdges: false,
    },
  },
  fcose: {
    name: "fcose",
    animate: true,
  },
  cose: {
    name: "cose",
    animate: true,
  },

  cola: {
    name: "cola",
    animate: true,
    maxSimulationTime: 40000,
  },
  dagre: {
    name: "dagre",
    animate: true,
  },
};

["box", "disco", "force", "layered", "mrtree", "random", "stress"].forEach(
  (elkAlgo) => {
    layouts[`elk_${elkAlgo}`] = {
      name: "elk",
      animate: true,
      elk: {
        algorithm: elkAlgo,
      },
    };
  }
);

export const customLayout = {
    name: "preset",
    // positions: undefined, // map of (node id) => (position obj); or function(node){ return somPos; }
    zoom: true, // the zoom level to set (prob want fit = false if set)
    // pan: undefined, // the pan level to set (prob want fit = false if set)
    // fit: true, // whether to fit to viewport
    padding: 50, // padding on fit
    animate: true, // whether to transition the node positions
    animationDuration: 500, // duration of animation in ms if enabled
    animationEasing: undefined, // easing of animation if enabled
    animateFilter: function (node, i) {
      return true;
    }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
    ready: undefined, // callback on layoutready
    stop: undefined, // callback on layoutstop
    transform: function (node, position) {
      return position;
    }, // transform a given node position. Useful for changing flow direction in discrete layouts
  };
