import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { createUser } from "../../../api/user";
import { useUserAuth } from "../../../context/userAuthContext";
import { ROLES } from "../../../utils/entities";
import { isStrongPassword, isValidEmail } from "../../../utils/helpers";
import Logo from "../../../assets/logo.png";
import Loading from "../../shared/spinner/Loading";

const Signup = () => {
  const {
    signUp,
    sendSignInLink,
    isAfterEmailVerification,
    checkIfEmailExists,
  } = useUserAuth();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isTeacher, setIsTeacher] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [isAfterVerification, setIsAfterVerification] = useState(false);
  const [sending, setSending] = useState(false);

  const getFormError = () => {
    if (name === "") {
      return "Name is a required field";
    }
    if (!isStrongPassword(password)) {
      return "That password is too easy to guess. Please enter one with at least 8 characters, at least one capital letter, at least one lowercase letter, and at least one number.";
    }
    if (password !== repeatPassword) {
      return "The passwords you entered do not match. Please re-enter your password and try again.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Sign up
    if (isAfterVerification) {
      const error = getFormError();
      if (name === "") {
        setError("Name is a required field");
        return;
      }
      if (error === "") {
        try {
          const { user } = await signUp(email, password);
          try {
            setSending(true);
            await createUser({
              firebaseUid: user.uid,
              name,
              role: isTeacher ? ROLES.TEACHER : ROLES.STUDENT,
            });
            navigate("/");
          } catch (error) {
            console.log(error);
          } finally {
            setSending(false);
          }
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            setError("This email address is already in use.");
          } else {
            setError(error.message);
          }
          setTimeout(() => {
            setError(null);
          }, 4000);
        }
      } else {
        setError(error);
      }
    } else {
      // Send sign up email link
      if (isValidEmail(email)) {
        try {
          setSending(true);
          const emailExists = await checkIfEmailExists(email);
          if (emailExists) {
            setError("Email already exists, please sign in.");
          } else {
            setTimeout(() => {
              navigate("/email-link-sent");
            }, 1000);
            await sendSignInLink(email);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setSending(false);
        }
      } else {
        setError("Email is invalid");
      }
    }
  };

  const getSignInStatus = async () => {
    const verifiedEmail = searchParams.get("email_verified");
    if (verifiedEmail) {
      const afterVerification = await isAfterEmailVerification();
      if (afterVerification) {
        setEmail(verifiedEmail);
        setIsAfterVerification(true);
      }
    }
  };

  useEffect(() => {
    getSignInStatus().then();
  });

  return (
    <>
      <form className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" alt="UC Irvine" src={Logo} />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign up
            </h2>
          </div>
          <div className="mt-8 space-y-6">
            <div className="-space-y-px rounded-md shadow-sm">
              {!isAfterVerification ? (
                <input
                  id="email-address"
                  name="email-address"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address (please use your official school email)"
                />
              ) : (
                <>
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="name"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Name"
                    />
                  </div>

                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                      className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Password"
                    />
                  </div>
                  <div>
                    <label htmlFor="repeatPassword" className="sr-only">
                      Password
                    </label>
                    <input
                      id="repeatPassword"
                      name="repeatPassword"
                      type="password"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      autoComplete="repeat-password"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Repeat password"
                    />
                  </div>
                </>
              )}
            </div>
            {isAfterVerification && (
              <div className="text-sm flex justify-between">
                {/*<div className="flex items-center cursor-pointer ">*/}
                {/*  <input*/}
                {/*    id="teacher"*/}
                {/*    onChange={() => setIsTeacher(!isTeacher)}*/}
                {/*    type="checkbox"*/}
                {/*    checked={isTeacher}*/}
                {/*    className="rounded checked mr-1 text-sm"*/}
                {/*  />*/}
                {/*  <label htmlFor="teacher">Teacher</label>*/}
                {/*</div>*/}
                <div className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
                  Forgot your password?
                </div>
              </div>
            )}

            <div>
              <button
                onClick={handleSubmit}
                type="submit"
                disabled={sending}
                className="group relative flex w-full items-center gap-2 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {sending ? <Loading /> : null}
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                {sending ? "Please wait..." : "Sign up"}
              </button>
            </div>
            <div className="p-4 text-center">
              Already have an account?{" "}
              <Link
                to="/login"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Log in
              </Link>
            </div>
            <div>
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Signup;
