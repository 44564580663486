const Button = ({
  label,
  onClick = () => {},
  icon,
  disabled = false,
  classes = "",
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        disabled && "disabled:opacity-50"
      } text-indigo-600 flex items-center justify-center group relative flex 
      w-full justify-center rounded-md border border-indigo-600 py-2 px-4 
      text-sm font-medium hover:bg-[#f8f8ff] focus:outline-none 
      focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${classes}`}
    >
      {icon}
      {label}
    </button>
  );
};

export default Button;
