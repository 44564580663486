import React, { useState } from "react";
import ConfirmationPopup from "./confirmation-popup/ConfirmationPopup";
import {AiOutlineClose} from "react-icons/ai";

const Popup = ({
  show,
  onSubmit = () => {},
  onCancel,
  allowSubmit = true,
  title,
  content,
  contentComponent,
  footerComponent,
  submitText = "Submit",
  cancelText = "Cancel",
  width = 500,
  needConfirmation = false,
  confirmationContent = "Are you sure?",
  showFooter = true,
}) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const submit = () => {
    if (needConfirmation) {
      setShowConfirmPopup(true);
    } else {
      onSubmit();
    }
  };

  return (
    <>
      {show && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ width }}
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {title && (
                  <div className="flex items-center justify-center justify-between py-2 px-4 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="mt-2 text-center text-2xl font-bold tracking-tight text-gray-900">
                      {title}
                    </h3>
                    <AiOutlineClose className={`cursor-pointer`} onClick={onCancel} />
                  </div>
                )}

                {/*body*/}
                <div className="relative px-6 py-3 flex-auto">
                  {contentComponent || (
                    <p className="my-4 text-slate-500 text-lg leading-relaxed whitespace-pre-line">
                      {content}
                    </p>
                  )}
                </div>
                {/*footer*/}
                {showFooter && (footerComponent || (
                  <div className="flex flex-col p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      onClick={submit}
                      type="submit"
                      disabled={!allowSubmit}
                      className={`${
                        !allowSubmit && "disabled:opacity-50"
                      } group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                      {submitText}
                    </button>
                    {/*{onCancel && (*/}
                    {/*  <button*/}
                    {/*    type="button"*/}
                    {/*    onClick={onCancel}*/}
                    {/*    className="text-sm font-medium text-indigo-600 hover:text-indigo-500 mt-3"*/}
                    {/*  >*/}
                    {/*    {cancelText}*/}
                    {/*  </button>*/}
                    {/*)}*/}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {needConfirmation && showConfirmPopup && (
        <ConfirmationPopup
          show={showConfirmPopup}
          content={confirmationContent}
          onClose={() => setShowConfirmPopup(false)}
          onConfirm={onSubmit}
          onReject={() => setShowConfirmPopup(false)}
        />
      )}
    </>
  );
};

export default Popup;
