import {useRef, useState} from "react";
import Cyto from "../../../shared/cyto/Cyto";
import ReplaceDefaultMapPopup from "./replace-default-map-popup/ReplaceDefaultMapPopup";
import SetConceptsPopup from "./set-concepts-popup/SetConceptsPopup";
import SetRelationshipsPopup from "./set-relationshpis-popup/SetRelationshipsPopup";
import StudentsPanel from "./students-panel/StudentsPanel";

const TeacherProject = ({ project, students, fetchProject, onRemoveStudent }) => {
  const cytoRef = useRef();
  const [showReplaceMapPopup, setShowReplaceMapPopup] = useState(false);
  const [showPossibleRelationshipsPopup, setShowPossibleRelationshipsPopup] =
    useState(false);
  const [showPossibleConceptsPopup, setShowPossibleConceptsPopup] =
    useState(false);
  const [refreshKey, setRefreshKey] = useState(Math.random());

  const getMapToShow = () => {
    if (project.mergedMap) {
      return { name: "Merged Map", map: project.mergedMap };
    } else {
      return { name: "Default Map", map: project.defaultMap };
    }
  };

  return (
    <>
      {project && (
        <div
          className="flex w-full flex"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="flex flex-col flex-1 px-3 divide-y basis-3/4">
            <div className="flex-1 flex flex-col pt-3">
              <div className="flex mb-3 gap-3">
                <button
                  disabled={students.length > 0}
                  onClick={() => {
                    setShowReplaceMapPopup(true);
                  }}
                  className={`${
                    students.length > 0 && "disabled:opacity-50"
                  } text-sm bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-3 border border-gray-400 rounded shadow`}
                >
                  Add or replace map
                </button>
                <button
                  onClick={() => setShowPossibleRelationshipsPopup(true)}
                  className="text-sm flex items-center bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-3 border border-gray-400 rounded shadow"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Set possible relationship types
                </button>
              </div>
              <div className="flex-1 rounded-lg bg-white h-[500px] border-2 border-dashed border-gray-200">
                <Cyto
                  project={project}
                  ref={cytoRef}
                  key={refreshKey}
                  mapName={getMapToShow().name}
                  mapId={getMapToShow().map.id}
                  nodes={getMapToShow().map.entities.concepts}
                  edges={getMapToShow().map.entities.relationships}
                  requireCitations={project.requireCitations}
                  editable={project?.mergedMap === null}
                />
              </div>
            </div>
          </div>
          <StudentsPanel
            students={students}
            project={project}
            onMerge={fetchProject}
            onRemoveStudent={onRemoveStudent}
          />
        </div>
      )}
      {project && showReplaceMapPopup && (
        <ReplaceDefaultMapPopup
          project={project}
          show={showReplaceMapPopup}
          onClose={() => {
            setShowReplaceMapPopup(false)
          }}
          onReplaceMap={() => {
            window.location.reload();
            setRefreshKey(Math.random());
          }}
        />
      )}
      {project && showPossibleRelationshipsPopup && (
        <SetRelationshipsPopup
          project={project}
          show={showPossibleRelationshipsPopup}
          onClose={() => setShowPossibleRelationshipsPopup(false)}
          onSet={async () => {
            await fetchProject();
            setRefreshKey(Math.random());
          }}
        />
      )}
      {project && showPossibleConceptsPopup && (
        <SetConceptsPopup
          project={project}
          show={showPossibleConceptsPopup}
          onClose={() => setShowPossibleConceptsPopup(false)}
          onSet={fetchProject}
        />
      )}
    </>
  );
};

export default TeacherProject;
