import NavBar from "../home/nav-bar/NavBar";
import React, { useEffect, useState } from "react";
import {
  createGrading,
  getGradingDetails,
  getGradings,
  updateGradingScore,
} from "../../../api/gradings";

// styles
import AddGrading from "./AddGrading";
import { Button, Dropdown, Table } from "flowbite-react";
import UploadGradingsCSV from "./UploadGradingsCSV";
import { useSearchParams } from "react-router-dom";
import ManageTriple from "./ManageTriple";

const Gradings = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [details, setDetails] = useState([]);
  const [creating, setCreating] = useState(false);
  const [gradings, setGradings] = useState([]);
  const [selectedGrading, setSelectedGrading] = useState();
  const [isAddCourseGradingOpen, setIsAddCourseGradingOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [manageTripleVisible, setManageTripleVisible] = useState(false);
  const [updatingTriple, setUpdatingTriple] = useState(false);
  const [currentTriple, setCurrentTriple] = useState(null);
  let [searchParams] = useSearchParams();

  const editTriple = (triple) => {
    setCurrentTriple(triple);
    setManageTripleVisible(true);
  };

  const fetchGradings = async () => {
    try {
      setLoading(true);
      const data = await getGradings();
      setGradings(data);
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchGradingDetails = async (gradingId) => {
    try {
      setLoadingDetails(true);
      const data = await getGradingDetails(gradingId);
      setDetails(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    fetchGradings().then((gradings) => {
      const id = searchParams.get("id");
      if (id) {
        const g = gradings.find((grading) => grading.id === id);
        setSelectedGrading(g);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedGrading) fetchGradingDetails(selectedGrading.id);
  }, [selectedGrading]);

  const onCreateGrading = async (gradingName) => {
    try {
      setCreating(true);
      await createGrading({ name: gradingName });
      const newGradings = await fetchGradings();
      const grading = newGradings.find(
        (grading) => grading.name === gradingName,
      );
      setSelectedGrading(grading);
    } catch (e) {
      console.log(e);
    } finally {
      setCreating(false);
      setIsAddCourseGradingOpen(false);
    }
  };

  const onUpdatedGrading = async (triple) => {
    try {
      setUpdatingTriple(true);
      setDetails((prev) => {
        return prev.map((t) => {
          if (
            t.concept1 === triple.concept1 &&
            t.concept2 === triple.concept2 &&
            t.relationshipType === triple.relationshipType
          ) {
            return { ...t, score: triple.score };
          }
          return t;
        });
      });
      updateGradingScore(selectedGrading.id, triple);
    } catch (e) {
      console.log(e);
    } finally {
      setCurrentTriple(null);
      setManageTripleVisible(false);
    }
  };

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="min-h-full flex flex-col">
        <header className="bg-white shadow">
          <div className="flex items-center gap-2 flex-wrap px-4 py-4">
            <span
              className={`ml-2 text-md text-gray-400 text-regular uppercase`}
            >
              Gradings
            </span>
            <span className="text-md tracking-tight text-gray-900"></span>
          </div>
        </header>
        <div className="p-4">
          {loading && <div>Loading...</div>}
          <div className="flex justify-between">
            <Dropdown
              label={selectedGrading ? selectedGrading.name : "Select Course"}
            >
              {gradings?.map((grading) => (
                <Dropdown.Item
                  key={grading.id}
                  onClick={() => setSelectedGrading(grading)}
                >
                  {grading.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
            <div className="flex items-center gap-2">
              <Button
                color="blue"
                disabled={!selectedGrading}
                onClick={() => {
                  setIsUploadOpen(true);
                }}
              >
                Upload Unique Relationships Grade Sheet
              </Button>
            </div>
          </div>
          <ManageTriple
            visible={manageTripleVisible}
            setVisible={setManageTripleVisible}
            triple={currentTriple}
            updating={updatingTriple}
            onSubmit={onUpdatedGrading}
          />
          <div className="overflow-x-auto py-8">
            {loadingDetails && <div>Loading...</div>}
            {!loadingDetails && (
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>Concept 1</Table.HeadCell>
                  <Table.HeadCell>Relationship Type</Table.HeadCell>
                  <Table.HeadCell>Concept 2</Table.HeadCell>
                  <Table.HeadCell>SCore</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit Score</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {details?.map((triple) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={`${triple.concept1}-${triple.relationshipType}-${triple.concept2}`}
                    >
                      <Table.Cell>{triple.concept1}</Table.Cell>
                      <Table.Cell>{triple.relationshipType}</Table.Cell>
                      <Table.Cell>{triple.concept2}</Table.Cell>
                      <Table.Cell>{triple.score}</Table.Cell>
                      <Table.Cell>
                        <span
                          className={"cursor-pointer text-blue-500"}
                          onClick={() => editTriple(triple)}
                        >
                          Edit
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </div>
        </div>
        <AddGrading
          visible={isAddCourseGradingOpen}
          setVisible={setIsAddCourseGradingOpen}
          creating={creating}
          onCreate={onCreateGrading}
        />
        <UploadGradingsCSV
          visible={isUploadOpen}
          setVisible={setIsUploadOpen}
          courseGrading={selectedGrading}
          onDone={() => fetchGradingDetails(selectedGrading.id)}
        />
      </div>
    </div>
  );
};

export default Gradings;
